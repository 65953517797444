import _ from 'lodash';
import React, { Component } from 'react';
import PrimaryButton from '../buttons/primaryButton';
import Loader from '../loader/loader';
import Subpage from './subpage';

class List extends Component {

    renderResourcesCards = () => {
        const { resources, history, deleteResource } = this.props;
        if (!resources) {
            return null;
        } else {
            return resources.map((resource, index) => {
                return <Subpage
                    key={index}
                    resource={resource}
                    history={history}
                    deleteResource={deleteResource}
                />
            });
        }
    }

    goToDataForm = () => {
        const { history } = this.props;
        history.push("/resources/data");
    }


    renderSiteTitle = () => {
        const { selectedForm, forms, match } = this.props;
        let title = "Lista zasobów";
        if (selectedForm) {
            title = selectedForm.name;
        } else {
            let formId = _.get(match, 'params.id', null);
            if (formId) {
                let form = _.find(forms, (f) => f._id === formId);
                if (form) {
                    title = form.name;
                }
            }
        }
        return (
            <section className="cms-page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{title}</h1>
                        </div>
                    </div>
                </div>
            </section>);
    }

    render() {
        const { loading } = this.props;
        const resources = this.renderResourcesCards();
        const title = this.renderSiteTitle();

        if (loading) {
            return (<div className="cms-items">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-12">
                            <Loader />
                        </div>
                    </div>
                </div>
            </div>)
        }
        return (
            <div>
                {title}
                <div className="cms-multipage">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <PrimaryButton
                                    onPrimaryButtonClick={this.goToDataForm}
                                    icon="submit"
                                    name="Dodaj zasób"
                                />
                            </div>
                            <div className="col-12">
                                {resources}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { clearParams } = this.props;
        clearParams();
    }

}


export default List;