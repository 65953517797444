import produce from "immer";

import {
    IS_LOADING,
    SET_RESOURCES,
    CLEAR_RESOURCES,
    SET_PARAMS,
    CLEAR_PARAMS
} from '../actions/resources';

const initial_state = {
    loaded: false,
    loading: false,
    resources: null,
    params: {
        "sort[createdAt]": -1
    }
}

const resourcesReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING:
                draft.loading = action.loading;
                break;
            case SET_RESOURCES:
                draft.loaded = true;
                draft.resources = action.resources;
                break;
            case CLEAR_RESOURCES:
                draft.loaded = false;
                draft.resources = null;
                break;
            case SET_PARAMS: {
                draft.params = action.params;
                break;
            }
            case CLEAR_PARAMS: {
                draft.params = initial_state.params;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default resourcesReducer;