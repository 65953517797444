import React from 'react';
import { connect } from 'react-redux';

import Auth from '../components/auth';

import { login } from '../actions/auth';

class AuthContainer extends React.Component {
    render() {
        return (
            <div>
                <Auth {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => {
            dispatch(login(username, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);