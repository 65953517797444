import React, { Component } from 'react';

import ItemTitleInput from '../inputs/itemTitleInput';
import BasicInput from '../inputs/basicInput';

export default class FileInputField extends Component {

    state = {
        nameError: null,
        labelError: null
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ nameError: null, labelError: null })
        if (nextProps.errors !== this.props.errors) {

            nextProps.errors.forEach(error => {
                switch (error.field) {
                    case 'label':
                        this.setState({ labelError: error.error })
                        break;
                    case 'name':
                        this.setState({ nameError: error.error })
                        break;
                    default: return;
                }
            })
        }
    }

    render() {
        return (
            <div className="cms-group">
                <div className="cms-group__content">
                    <div className="col-12">
                        <div className="cms-item cms-file">
                            <ItemTitleInput
                                switchFieldName={'File' + Date.now()}
                                isRequiredFieldName={this.props.isRequiredFieldName}
                                placeholder={this.props.namePlaceholder}
                                icon="file"
                                value={this.props.value}
                                name={this.props.name}
                                multiple={this.props.multiple}
                                required={this.props.required}
                                error={this.state.nameError}
                                id={this.props.id}
                                onChange={(e, name) => this.props.onChange(e, name)}
                            />
                        </div>
                        <BasicInput
                            type="text"
                            label="Label"
                            placeholder="Wpisz treść labela"
                            defaultValue={this.props.label}
                            error={this.state.labelError}
                            onChange={(e) => this.props.onChange(e, 'label')}
                        />
                    </div>
                </div>
            </div>
        )
    }

}