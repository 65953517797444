import React, { Component } from 'react';
import _ from 'lodash';

export default class RadioInput extends Component {

    onChange = (e) => {
        const { onChange, path } = this.props;
        let value = e.target.value;
        onChange(path, value);
    }

    renderOptions = () => {
        const { options, name, value } = this.props;

        return options.map((option, index) => {
            let isChecked = _.includes(value, option.value);
            return (
                <div className="form-check form-check-inline" key={`${name}_opt${index}`} style={{ "marginRight": "4.75rem" }}>
                    <input className="form-check-input" value={option.value} name={name} type="checkbox" onChange={this.onChange} checked={isChecked} />
                    <label className="form-check-label">{option.label}</label>
                </div>);
        });
    }

    renderError = () => {
        const { errors, path } = this.props;
        if (errors && errors[path]) {
            return <div><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    render() {
        const { label, wrap } = this.props;
        const options = this.renderOptions();
        const errorText = this.renderError();

        return (<div className="col-12 mt-3">
            <div className={wrap ? "cms-group" : ""}>
                <div className="cms-group__content">
                    <div className="cms-list">
                        <div className="cms-item__header">
                            <h1>{label}</h1>
                        </div>
                        <div className="cms-item__body" style={{ padding: "3rem 1.5rem" }}>
                            {options}
                        </div>
                        {errorText}
                    </div>
                </div>
            </div>
        </div>);
    }
}

