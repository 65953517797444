import React from 'react';
import { connect } from 'react-redux';
import { setLanguagueFilter } from "../actions/resources";
import { setActiveLangForm } from "../actions/resource";
import { addLanguagueToSettings, deleteLanguageFromSettings, editLanguagueInSettings, getSettings, clearErrors } from '../actions/header';
import Header from '../components/header/header';



class HeaderContainer extends React.Component {
    render() {
        return (
            <div>
                <Header {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        header: store.header,
        form: store.resource.form,
        selectedLanguague: store.resource.languague,
        error: store.header.error,
        loadedList: store.resources.loaded,
        params: store.resources.params
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addLanguagueToSettings: (form, toggleModal) => {
            dispatch(addLanguagueToSettings(form, toggleModal))
        },
        editLanguagueInSettings: (form, id, toggleModal) => {
            dispatch(editLanguagueInSettings(form, id, toggleModal))
        },
        deleteLanguageFromSettings: (id) => {
            dispatch(deleteLanguageFromSettings(id))
        },
        getSettings: () => {
            dispatch(getSettings())
        },
        setActiveLangForm: (languague) => {
            dispatch(setActiveLangForm(languague));
        },
        clearErrors: () => {
            dispatch(clearErrors())
        },
        setLanguagueFilter: (languague) => {
            dispatch(setLanguagueFilter(languague));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);