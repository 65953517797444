import React from 'react';
import ListContainer from "../containers/listContainer";
import { Route } from 'react-router-dom';
import ResourceFormContainer from "../containers/resource/formContainer";
import FormContainer from '../containers/form/formContainer';

class Items extends React.Component {

    render() {
        return (
            <div>
                <Route exact path="/resources/list/:id" component={ListContainer} />
                <Route exact path="/resources/resource/:id?" component={FormContainer} />
                <Route exact path="/resources/data/:id?/:lang?" component={ResourceFormContainer} />
            </div>
        )
    }

}

export default Items;