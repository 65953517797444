import React, { Component } from 'react';
import PencilIcon from '../../assets/icons/icon--edit.svg'

import SwitchInput from './switchInput/switchInput';

export default class PageTitleInput extends Component {

    renderError = () => {
        const { error } = this.props;

        if (error) return <div><span className="text-danger">{error}</span></div>

        return null;
    }

    onChangeMultiple = () => {
        this.props.onChangeMultiple()
    }

    render() {

        return (
            <section className="cms-page-title">
                <div className="container">
                    <div className="row">
                        <div className="col-9">

                            <input
                                className="cms-page-title__input"
                                type={this.props.type}
                                placeholder={this.props.placeholder}
                                onChange={this.props.onChange}
                                value={this.props.value}
                            />

                            <span>
                                <img src={PencilIcon} alt="PencilIcon" />
                            </span>

                        </div>

                        <div className="col-3">
                            <SwitchInput
                                name="MultiStrona"
                                switchFieldName={'PageTitle' + Date.now()}
                                defaultValue={this.props.defaultValue}
                                id={this.props.id}
                                onChange={this.onChangeMultiple}
                            />
                        </div>

                    </div>
                    {this.renderError()}
                </div>
            </section>
        )
    }
}
