import React from 'react';
import _ from 'lodash';

import Loader from '../loader/loader';
import PrimaryButton from '../buttons/primaryButton';
import SecondaryButton from '../buttons/secondaryButton';
import FileUploader from './fileUploader';
import File from './file';
import FilePreview from './filePreview';

class Library extends React.Component {

    state = {
        isDropdownOpen: false
    }

    componentDidMount = () => {
        this.props.getFiles();
    }

    isDropdownOpen = (isOpen) => {
        this.setState({
            isDropdownOpen: isOpen
        });
    }

    renderFileUploader = () => {
        return (
            <FileUploader
                addFileToUpload={this.props.addFileToUpload}
                isLoading={this.props.isLoading}
            />
        )
    }

    handleDeleteFilePreview = (i) => {
        this.props.deleteFileFromPreview(i);
    }

    renderPreview = () => {
        const { filesToUpload } = this.props.library;
        if (filesToUpload && filesToUpload.length > 0) {
            return (
                <div className="cms-item--files preview">
                    <div className="row d-flex justify-content-center" style={{ padding: 10 }}>
                        {this.getFilePreview()}
                    </div>
                </div>
            )
        }
    }
    getFilePreview = () => {
        const { filesToUpload } = this.props.library;
        return filesToUpload.map((file, i) => {
            return (
                <FilePreview
                    key={i}
                    i={i}
                    file={file}
                    deleteFileFromPreview={this.handleDeleteFilePreview}
                />
            )
        })
    }

    renderFiles = () => {
        const { files } = this.props.library;
        if (files && files.length > 0) {
            return (
                <div className="cms-item--files">
                    <div className="row d-flex justify-content-space-between" style={{ padding: 10 }}>
                        {this.getFiles()}
                    </div>
                </div>
            )
        }
    }

    getFiles = () => {
        const { apiHost, deleteFile, addFileToForm, removeFileFromForm, toggleLibrary, library } = this.props;

        return library.files.map(file => {
            let isChecked = false;
            let index = _.findIndex(library.selectedFiles[library.inputName], { _id: file._id });
            if (index > -1) {
                isChecked = true;
            }

            return (
                <File
                    isChecked={isChecked}
                    key={file._id}
                    file={file}
                    deleteFile={deleteFile}
                    addFileToForm={addFileToForm}
                    removeFileFromForm={removeFileFromForm}
                    isOpenFromForm={library.isOpenFromForm}
                    inputName={library.inputName}
                    canSelectMany={library.canSelectMany}
                    toggleLibrary={toggleLibrary}
                    apiHost={apiHost}
                />
            )
        })
    }

    renderLoadMore = () => {
        const { changeOffset, total, files } = this.props;
        if (files.length == total) {
            return null;
        }
        return (<div className="text-center col-lg-12 mt-3">
            <PrimaryButton
                icon="none"
                name="Ładuj więcej"
                onPrimaryButtonClick={changeOffset} />
        </div>);
    }

    renderModalBody = () => {
        if (this.props.library.loading) {
            return (
                <div className="modal-body p-5 d-flex justify-content-center" >
                    <Loader />
                </div>
            )
        }
        return (
            <div className="modal-body p-5" >
                {this.renderFileUploader()}
                {this.renderPreview()}
                {this.renderFiles()}
                {this.renderLoadMore()}
            </div>
        )
    }

    handleSaveButtonClick = () => {
        this.props.uploadFiles();
    }

    handleCancelButtonClick = () => {
        this.props.toggleLibrary();
        this.props.clearFilesToUpload();
    }
    renderFooter = () => {
        const { filesToUpload } = this.props.library;
        if (filesToUpload && filesToUpload.length > 0) {
            return (
                <div className="modal-footer p-5">
                    <PrimaryButton
                        icon="none"
                        name="Zapisz"
                        onPrimaryButtonClick={this.handleSaveButtonClick}
                    />
                    <SecondaryButton
                        icon="none"
                        name="Anuluj"
                        onSecondaryButtonClick={this.handleCancelButtonClick}
                    />
                </div>
            )
        }
    }

    handleCloseLibrary = () => {
        this.props.toggleLibrary();
    }

    render() {
        return (
            <div className="modal fade show" id="cms-files__modal" tabIndex="-1" role="dialog" aria-hidden="true" style={{ height: '100%', overflowY: 'initial', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                <div className="modal-dialog" role="document" style={{ width: "100%", height: "100%" }}>
                    <div className="modal-content">
                        <div className="modal-header p-5">
                            <h2>Biblioteka</h2>
                            {/* Close Modal Button */}
                            <button
                                onClick={this.handleCloseLibrary}
                                type="button"
                                className="close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {this.renderModalBody()}
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { clearOffset } = this.props;
        clearOffset();
    }
}

export default Library;