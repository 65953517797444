import Api from '../services/api';
import { toast } from 'react-toastify';
import { onResourceChange } from "./resource";
import _ from 'lodash';

const namespace = 'LIBRARY';

export const TOGGLE_LIBRARY = `TOGGLE_LIBRARY_${namespace}`;
export const ADD_FILE_TO_UPLOAD = `ADD_FILE_TO_UPLOAD_${namespace}`;
export const CLEAR_FILES_TO_UPLOAD = `CLEAR_FILES_TO_UPLOAD_${namespace}`;
export const SET_FILES = `SET_FILES_${namespace}`;
export const DELETE_FILE_FROM_PREVIEW = `DELETE_FILE_FROM_PREVIEW_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const INSERT_INTO_SELECTED_FILES = `INSERT_INTO_SELECTED_FILES_${namespace}`;
export const REMOVE_FROM_SELECTED_FILES = `REMOVE_FROM_SELECTED_FILES_${namespace}`;
export const CLEAR_SELECTED_FILES = `CLEAR_SELECTED_FILES_${namespace}`;
export const SET_INPUT_NAME = `SET_INPUT_NAME_${namespace}`;
export const CLEAR_INPUT_NAME = `CLEAR_INPUT_NAME_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;
export const CLEAR_OFFSET = `CLEAR_OFFSET_${namespace}`;

export const isLoading = () => dispatch => {
    dispatch({
        type: IS_LOADING
    })
}

export const toggleLibrary = (isOpenFromForm = false, inputName = null, canSelectMany = false) => dispatch => {
    dispatch({
        type: TOGGLE_LIBRARY,
        isOpenFromForm,
        inputName,
        canSelectMany
    });
}

export const addFileToUpload = (file) => (dispatch) => {
    dispatch({
        type: ADD_FILE_TO_UPLOAD,
        file
    })
}

export const removeFileFromForm = (id) => (dispatch, getState) => {
    const { library: { inputName, selectedFiles } } = getState();

    let newSelected = Object.assign({}, selectedFiles);
    if (_.isArray(newSelected[inputName])) {
        newSelected[inputName] = _.reject(newSelected[inputName], (f) => f._id === id);
    }
    let value = newSelected[inputName].map((f) => f.src);
    if (_.isEmpty(value)) {
        value = undefined;
    }
    dispatch({
        type: INSERT_INTO_SELECTED_FILES,
        selectedFiles: newSelected
    });
    dispatch(onResourceChange(inputName, value));
}

export const addFileToForm = (file) => (dispatch, getState) => {
    const { library: { inputName, canSelectMany }, resource: { form } } = getState();

    let value = file.src;
    let currentValue = _.get(form, `${inputName}.value`, null);
    if (currentValue) {
        if (canSelectMany) {
            dispatch(onResourceChange(inputName, _.uniq(_.concat(currentValue, _.castArray(value)))));
        } else {
            dispatch(onResourceChange(inputName, value));
        }
    } else {
        if (canSelectMany) {
            dispatch(onResourceChange(inputName, _.castArray(value)));
        } else {
            dispatch(onResourceChange(inputName, value));
        }
    }
}

export const uploadFiles = () => (dispatch, getState) => {
    const files = getState().library.filesToUpload;
    files.forEach(file => {
        dispatch(isLoading());
        let formData = new FormData();
        formData.append('file', file.file);
        if (file.avatar) {
            formData.append('avatar', file.avatar);
        }
        Api.post('/resource/file', formData, true, true).then(res => {
            dispatch(isLoading());

            if (res.success) {
                toast('Plik został dodany')
                dispatch(getFiles());
                dispatch(clearFilesToUpload());
            } else {
                toast('Nie udało się dodać pliku')
            }
        }).catch(err => {
            dispatch(isLoading());
            console.error(err)
        })
    })
}

export const clearFilesToUpload = () => dispatch => {
    dispatch({
        type: CLEAR_FILES_TO_UPLOAD
    })
}

export const deleteFileFromPreview = (i) => dispatch => {
    dispatch({
        type: DELETE_FILE_FROM_PREVIEW,
        i
    })
}

export const setFiles = (files, total) => dispatch => {
    dispatch({
        type: SET_FILES,
        files,
        total
    });
}

export const addFiles = (toAdd, total) => (dispatch, getState) => {
    const { library: { files } } = getState();
    let newFiles = _.concat(files, toAdd);
    dispatch(setFiles(newFiles, total));
}

export const getFiles = () => (dispatch, getState) => {
    const { library: { offset } } = getState();
    dispatch(isLoading());
    let params = {
        "limit": 20,
        "offset": offset,
        "sort[createdAt]": -1
    };
    Api.get('/resource/file', params).then(res => {
        dispatch(isLoading());
        if (res.success) {
            if (offset > 0) {
                dispatch(addFiles(res.documents, res.total));
            } else {
                dispatch(setFiles(res.documents, res.total));
            }
        } else {
            toast('Nie udało sie pobrać plików')
        }
    }).catch(error => {
        dispatch(isLoading());
        console.error(error)
    })
}

export const deleteFile = (id) => dispatch => {
    dispatch(isLoading());
    Api.delete(`/resource/file/${id}`).then(res => {
        dispatch(isLoading());
        if (res.success) {
            toast('Usunięto plik')
            dispatch(getFiles());
        } else {
            toast('Usuwanie pliku nie powiodło się')
        }
    }).catch(err => {
        dispatch(isLoading());
        console.error(err)
    })
}

export const changeOffset = () => (dispatch, getState) => {
    const { library: { offset } } = getState();
    dispatch({
        type: SET_OFFSET,
        offset: offset + 20
    });
    dispatch(getFiles());
}

export const clearOffset = () => dispatch => {
    dispatch({
        type: CLEAR_OFFSET,
    });
}