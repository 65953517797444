export const langPL = {
    toolbar: {
        default: 'Domyślny',
        save: 'Zapisz',
        font: 'Czcionka',
        formats: 'Format',
        fontSize: 'Rozmiar',
        bold: 'Pogrubienie',
        underline: 'Podkreślenie',
        italic: 'Kursywa',
        strike: 'Przekreślenie',
        subscript: 'Indeks dolny',
        superscript: 'Indeks górny',
        removeFormat: 'Usuń formatowanie',
        fontColor: 'Kolor czcionki',
        hiliteColor: 'Kolor zaznaczenia',
        indent: 'Wcięcie z lewej',
        outdent: 'Wcięcie z prawej',
        align: 'Układ',
        alignLeft: 'Do lewej',
        alignRight: 'Do prawej',
        alignCenter: 'Do środka',
        alignJustify: 'Wyjustuj',
        list: 'Lista',
        orderList: 'Lista numerowana',
        unorderList: 'Lista punktowana',
        horizontalRule: 'horizontal line',
        hr_solid: 'ciągła',
        hr_dotted: 'kropkowana',
        hr_dashed: 'przerywana',
        table: 'Tabela',
        link: 'Link',
        image: 'Zdjęcie',
        video: 'Wideo',
        fullScreen: 'Pełny ekran',
        showBlocks: 'Pokaż bloki',
        codeView: 'Widok kodu',
        undo: 'Cofnij',
        redo: 'Powtórz',
        preview: 'Podgląd',
        print: 'Drukuj',
        tag_p: 'Paragraf',
        tag_div: 'Normalny (DIV)',
        tag_h: 'Nagłówek',
        tag_blockquote: 'Cudzysłów',
        tag_pre: 'Kod',
        template: 'Szablon'
    },
    dialogBox: {
        linkBox: {
            title: 'Wstaw link',
            url: 'Url',
            text: 'Tekst',
            newWindowCheck: 'Otwórz w nowym oknie'
        },
        imageBox: {
            title: 'Wstaw zdjęcie',
            file: 'Wybierz plik',
            url: 'Adres url',
            altText: 'Alternatywny tekst'
        },
        videoBox: {
            title: 'Wstaw wideo',
            url: 'Link Youtube'
        },
        caption: 'Wstaw opis',
        close: 'Zamknij',
        submitButton: 'Zatwierdź',
        revertButton: 'Przywróc',
        proportion: 'zachowaj proporcje',
        width: 'Szerokość',
        height: 'Wysokość',
        basic: 'Domyślne',
        left: 'Lewo',
        right: 'Prawo',
        center: 'Środek'
    },
    controller: {
        edit: 'Edytuj',
        remove: 'Usuń',
        insertRowAbove: 'Wstaw rząd powyżej',
        insertRowBelow: 'Wstaw rząd poniżej',
        deleteRow: 'Usuń rząd',
        insertColumnBefore: 'Wstaw kolumnę przed',
        insertColumnAfter: 'Wstaw kolumnę po',
        deleteColumn: 'Usuń kolumnę',
        resize100: 'Rozmiar 100%',
        resize75: 'Rozmiar 75%',
        resize50: 'Rozmiar 50%',
        resize25: 'Rozmiar 25%',
        mirrorHorizontal: 'Odbicie poziome',
        mirrorVertical: 'Odbicie pionowe',
        rotateLeft: 'Obróć w lewo',
        rotateRight: 'Obróć w prawo',
        maxSize: 'Max. rozmiar',
        minSize: 'Min. rozmiar',
        tableHeader: 'Nagłówek tabeli',
        mergeCells: 'Scal komórki',
        splitCells: 'Rozdziel komórki',
        HorizontalSplit: 'Rozdziel horyzontalnie',
        VerticalSplit: 'Rozdziel pionowo'
    }
};