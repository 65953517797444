import { toast } from 'react-toastify';
import Api from '../services/api';
import _ from 'lodash';

import { getForms } from "./forms";

const namespace = 'FORM';
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SAVE_FORM = `SAVE_FORM${namespace}`;
export const UPDATE_FORM = `SAVE_FORM${namespace}`;
export const SET_FORM = `SET_FORM${namespace}`;
export const CLEAR_ITEMS = `CLEAR_ITEMS${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;

export const isLoading = (loading) => dispatch => {
    dispatch({
        type: IS_LOADING,
        loading
    });
}

export const clearItems = () => dispatch => {
    dispatch({
        type: CLEAR_ITEMS
    });
}

export const setForm = (data) => dispatch => {
    dispatch({
        type: SET_FORM,
        form: data
    });
}

export const saveForm = (formData) => (dispatch, getState) => {
    dispatch(isLoading(true));
    Api.post('/resource/resource', formData).then(res => {
        dispatch(isLoading(false));

        if (res.success) {
            toast('zapisano formularz')
            dispatch(getForms());
            dispatch(clearItems());
            dispatch(clearErrors())
            dispatch(getForm(res.document._id))
        } else {
            if (res.err && _.isObject(res.err)) {
                dispatch(setValidationErrors(res.err, formData));
            }
            toast('Wystąpił błąd');
        }

    }).catch(err => {
        dispatch(isLoading());
        console.error(err)
    })
}

export const updateForm = (formData, id) => (dispatch, getState) => {
    dispatch(isLoading(true));
    Api.put(`/resource/resource/${id}`, formData).then(res => {
        dispatch(isLoading(false));

        if (res.success) {
            toast('zaktualizowano formularz')
            dispatch(getForms());
            dispatch(clearItems());
            dispatch(clearErrors())
            dispatch(getForm(id))
        } else {
            if (res.err && _.isObject(res.err)) {
                dispatch(setValidationErrors(res.err, formData));
            }
            toast('Nie udało się zaktualizować formularza')
        }

    }).catch(err => {
        dispatch(isLoading(false));
        console.error(err)
    })
}

export const getForm = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let res = await Api.get(`/resource/resource/${id}`);
    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setForm(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setValidationErrors = (errors, data) => (dispatch, getState) => {
    let parsed = {};

    _.each(errors, (value, key) => {
        if (_.includes(key, "fields") && key !== 'fields') {
            let props = _.split(key, ".");
            let path = "";

            props.map((prop, index) => {
                if (!(prop === 'fields' && index === 0)) {
                    path += !isNaN(prop) ? `[${prop}]` : `${index > 0 ? "." : ""}${prop}`;
                }

                return null;
            });

            parsed[path] = value;
        } else parsed[key] = value;
    });

    dispatch(clearErrors());
    dispatch({
        type: SET_ERRORS,
        errors: parsed
    });
}

//Funkcja czyści błędy walidacji formularza
export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
}