import React, { Component } from 'react';

export default class GroupInputFieldHeader extends Component {

    renderError = () => {
        const { error } = this.props;

        if (error) return <div><span className="text-danger">{error}</span></div>

        return null;
    }

    render() {
        return (
            <div>
                <div className="cms-group__header">
                    <span className="cms-group__angle">
                        <input
                            type="text"
                            className="cms-group__title"
                            placeholder="Nazwa sekcji"
                            defaultValue={this.props.name}
                            onChange={(e) => this.props.onChange(e, 'name')}
                            disabled={this.props.id ? true : false}
                        />
                        <span className="triangle"></span>
                    </span>
                </div>
                {this.renderError()}
            </div>
        )
    }

}