import produce from "immer";

import {
    IS_LOADING,
    SET_FORM,
    CLEAR_FORM,
    SET_ERRORS,
    CLEAR_ERRORS,
    SELECT_LANGUAGUE,
    CHANGE_RESOURCE_NAME,
    SET_LANG_FORM,
    CLEAR_ALL,
    SET_ACTIVE_LANG_FROM,
    SET_BASE_FORM,
    SET_RESOURCE_ID,
    CHANGE_IS_PRIVATE
} from '../actions/resource';

const initial_state = {
    loading: false,
    //Formularz bez podstawionych danych
    baseForm: {},
    //Formularze dla wszystkich dostępnych języków
    forms: {},
    //Aktualnie edytowany formularz w wybranym języku
    form: {},
    errors: {},
    name: null,
    languague: null,
    resourceId: null,
    private: false
}

const resourceReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING:
                draft.loading = action.loading;
                break;
            case SET_FORM:
                draft.form = action.form;
                break;
            case CLEAR_FORM:
                draft.form = {};
                draft.languague = null;
                draft.name = null;
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            case SELECT_LANGUAGUE:
                draft.languague = action.languague;
                break;
            case CHANGE_RESOURCE_NAME:
                draft.name = action.name;
                break;
            case SET_LANG_FORM: {
                draft.forms[action.lang] = action.form;
                break;
            }
            case CLEAR_ALL: {
                draft.resourceId = null;
                draft.forms = {};
                draft.errors = {};
                draft.form = {};
                draft.languague = null;
                draft.name = null;
                draft.private = false;
                break;
            }
            case SET_ACTIVE_LANG_FROM: {
                draft.form = draft.forms[action.lang];
                break;
            }
            case SET_BASE_FORM: {
                draft.baseForm = action.form;
                break;
            }
            case SET_RESOURCE_ID: {
                draft.resourceId = action.resourceId;
                break;
            }
            case CHANGE_IS_PRIVATE: {
                draft.private = action.private;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default resourceReducer;