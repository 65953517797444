import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { toggleLibrary } from '../actions/library';
import { getForms, setSelectedForm } from "../actions/forms";
import { loadSelectedForm, getResource, clearForm, clearAll } from "../actions/resource";
import { clearItems } from "../actions/form";
import { getResourcesByForm, clearResources } from "../actions/resources";
import { getForm } from '../actions/form';
import SidePanel from '../components/sidePanel';
import LibraryContainer from './libraryContainer';

class SidePanelContainer extends Component {

    componentDidMount() {
        const { getForms } = this.props;
        getForms();
    }

    renderLibrary = () => {
        if (this.props.library.libraryVisibility) {
            return (
                <LibraryContainer />
            )
        }
        return;
    }
    render() {
        return (
            <div>
                {this.renderLibrary()}
                <SidePanel {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        library: store.library,
        forms: store.forms.forms,
        selectedForm: store.forms.selectedForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => {
            dispatch(logout())
        },
        toggleLibrary: (isOpenFromForm, inputName, canSelectMany) => {
            dispatch(toggleLibrary(isOpenFromForm, inputName, canSelectMany))
        },
        getForms: () => {
            dispatch(getForms());
        },
        setSelectedForm: (form) => {
            dispatch(setSelectedForm(form));
        },
        getResourcesByForm: (formId) => {
            dispatch(getResourcesByForm(formId));
        },
        clearResources: () => {
            dispatch(clearResources());
        },
        loadSelectedForm: () => {
            dispatch(loadSelectedForm());
        },
        getResource: (resourceId, lang) => {
            dispatch(getResource(resourceId, lang));
        },
        clearForm: () => {
            dispatch(clearForm());
        },
        getForm: (id) => {
            dispatch(getForm(id))
        },
        clearItems: () => {
            dispatch(clearItems())
        },
        clearAll: () => {
            dispatch(clearAll());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanelContainer);