import React from 'react';

export default class BasicInput extends React.Component {

    renderError = () => {
        const { error } = this.props;

        if (error) return <div><span className="text-danger">{error}</span></div>

        return null;
    }

    render() {
        return (
            <div>
                <label
                    className="cms-item__label"
                >
                    {this.props.label}
                </label>

                <input
                    className="cms-item__input cms-input__label"
                    type={this.props.type}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    value={this.props.defaultValue}
                />

                {this.renderError()}
                <br />
            </div>

        )
    }
}