import React from 'react';

import Loader from './loader/loader';
import BasicInput from './inputs/basicInput';
import Logo from '../assets/images/cmsly-logo.png';

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                value: ''
            },
            password: {
                value: ''
            },
        };
    }

    handleInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        const newValue = {
            value: value,
        }

        this.setState({
            [name]: newValue
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const login = this.state.login.value;
        const password = this.state.password.value;
        this.props.login(login, password)
    }

    authForm = () => {
        return (
            <div className="auth-form">
                <div className="auth-logo">
                    <img src={Logo} alt="app logo" />
                </div>
                <form
                    className="d-flex align-items-center flex-column cms-item__body"
                    onSubmit={this.onFormSubmit}
                >
                    <BasicInput
                        label="Login"
                        type="text"
                        id="login"
                        onChange={this.handleInputChange}
                        value={this.state.login.value}
                    />

                    <BasicInput
                        label="Hasło"
                        type="password"
                        id="password"
                        onChange={this.handleInputChange}
                        value={this.state.password.value}
                    />

                    <button type="submit" className="btn-cms btn-cms--primary mr-4">Zaloguj</button>
                </form>
            </div>
        )
    }
    render() {
        const content = (this.props.auth.loading) ? <Loader /> : this.authForm()
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                {/* {this.authForm()} */}
                {content}
            </div>
        )
    }
}

export default Auth;