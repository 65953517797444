import React, { Component } from 'react';
import _ from 'lodash';

export default class SelectInput extends Component {

    onChange = (e) => {
        const { onChange, path } = this.props;
        let value = e.target.value;
        //html defaultowo zwraca nazwe opcji jeśli w value ma nulla albo undefined wiec dlatego ta linijka
        if(value === "Wybierz") {
            value = null;
        }
        onChange(path, value);
    }

    renderOptions = () => {
        const { options, name } = this.props;
        let blank = <option value={null} key="blank">Wybierz</option>;
        let selectOptions = options.map((option, index) => {
            return <option value={option.value} key={`${name}_opt${index}`}>{option.label}</option>
        });
        return _.concat([blank], selectOptions);
    }

    renderError = () => {
        const { errors, path } = this.props;
        if (errors && errors[path]) {
            return <div><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    render() {
        const { name, value, label, placeholder, wrap } = this.props;
        const options = this.renderOptions();
        const errorText = this.renderError();

        return (<div className="col-12 mt-3">
            <div className={wrap ? "cms-group" : ""}>
                <div className="cms-group__content">
                    <div className="cms-list">
                        <div className="cms-item__header">
                            <h1>{label}</h1>
                        </div>
                        <div className="cms-item__body">
                            <label className="cms-item__label">{placeholder ? placeholder : "Wybierz opcję"}</label>
                            <select
                                style={{ height: "3.5rem", "fontSize": "1.4rem" }}
                                className="cms-item__input cms-list__li form-control"
                                placeholder={placeholder}
                                onChange={this.onChange}
                                value={value}
                                name={name}
                            >
                                {options}
                            </select>
                            {errorText}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}