import React, { Component } from 'react';

import './switchInput.css';

export default class SwitchInput extends Component {

    render() {
        const name = this.props.switchFieldName ? this.props.switchFieldName : this.props.isRequiredFieldName;

        return (
            <div className="custom-control custom-switch d-flex justify-content-end" style={this.props.id ? { opacity: '0.4' } : null}>
                <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={this.props.defaultValue ? true : undefined}
                    id={name}
                    onClick={this.props.onChange}
                    disabled={this.props.id ? true : false} />
                <label className="custom-control-label" htmlFor={name}> {this.props.name} </label>
            </div>
        )
    }
}
