import React from 'react';

import PrimaryButton from '../buttons/primaryButton';
import SecondaryButton from '../buttons/secondaryButton';

export default class DeleteModal extends React.Component {
    handleCloseModal = () => {
        this.props.hideDeleteModal();
    }

    render() {
        return (
            <div className="modal" tabindex="-1" role="dialog" style={{backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Usuwanie pliku</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => this.handleCloseModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Czy na pewno chcesz usunąć ten plik?</p>
                        </div>
                        <div className="modal-footer">
                            <PrimaryButton
                                name="Usuń"
                                onPrimaryButtonClick={this.props.handleDeleteFile}
                            />
                            <SecondaryButton
                                onSecondaryButtonClick={this.handleCloseModal}
                                name="Anuluj"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 