import React, { Component } from 'react';
import ItemTitleInput from '../inputs/itemTitleInput';

import BasicInput from '../inputs/basicInput';

export default class TextInputField extends Component {

    state = {
        nameError: null,
        labelError: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors !== this.props.errors) {
            this.setState({ nameError: null, labelError: null, placeholderError: null })

            nextProps.errors.forEach(error => {
                switch (error.field) {
                    case 'label':
                        this.setState({ labelError: error.error })
                        break;
                    case 'name':
                        this.setState({ nameError: error.error })
                        break;
                    default: return;
                }
            })
        }
    }

    render() {
        const classes = this.props.showSection ? "cms-items" : null;

        return (
            <section className={classes}>
                <div className="cms-group">
                    <div className="cms-group__content">
                        <div className="col-12">
                            <div className="cms-item cms-list">
                                <ItemTitleInput
                                    icon={this.props.icon}
                                    placeholder={this.props.namePlaceholder}
                                    isRequiredFieldName={this.props.isRequiredFieldName}
                                    required={this.props.required}
                                    name={this.props.name}
                                    id={this.props.id}
                                    onChange={(e, name) => this.props.onChange(e, name)}
                                    error={this.state.nameError}
                                />
                                <div className="cms-item__body">
                                    <BasicInput
                                        type={"text"}
                                        label="Label"
                                        placeholder="Wpisz treść labela"
                                        defaultValue={this.props.label}
                                        onChange={(e) => this.props.onChange(e, 'label')}
                                        error={this.state.labelError}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

}