import produce from "immer";
import {
    LOGIN,
    SET_TOKEN,
    IS_AUTH,
    CLEAR,
    IS_LOADING
} from '../actions/auth';

const authDefaultState = {
    loading: false,
    isAuth: false,
    token: null,
    _id: null,
    company: null,
    dbName: null,
    exp: null,
    lat: null,
    isAdmin: false,
    name: null,
}

const authReducer = (state = authDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case CLEAR: {
                return authDefaultState;
            }
            case SET_TOKEN: {
                draft.token = action.token;
                break;
            }
            case IS_AUTH: {
                draft.isAuth = action.isAuth;
                break;
            }
            case LOGIN: {
                draft._id = action._id;
                draft.company = action.company;
                draft.dbName = action.dbName;
                draft.exp = action.exp;
                draft.lat = action.lat;
                draft.isAdmin = action.isAdmin;
                draft.name = action.name;
                break;
            }
            case IS_LOADING:
                draft.loading = !draft.loading;
                break;
            default: {
                return draft;
            }
        }
    });

}

export default authReducer;