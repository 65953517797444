import React from 'react';
import { connect } from 'react-redux';

import Items from '../components/items';
// import AddInputs from '../components/addInputs';

import HeaderContainer from '../containers/headerContainer';

import { toggleLibrary } from '../actions/library';

class ItemsContainer extends React.Component {
    renderHeader = () => {
        return <HeaderContainer />
    }

    render() {
        return (
            <main className="cms-content">
                {this.renderHeader()}
                <Items {...this.props} />
            </main>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleLibrary: (isOpenFromForm, inputName, canSelectMany) => {
            dispatch(toggleLibrary(isOpenFromForm, inputName, canSelectMany))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsContainer);