import React from 'react';
import { connect } from 'react-redux';
import { isLoading, saveForm, getForm, updateForm, clearErrors } from '../../actions/form';
import Form from '../../components/form/form';

class FormContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        const { history } = this.props;
        if (nextProps.form !== this.props.form) {
            if (nextProps.form && nextProps.form._id) history.push(`/resources/resource/${nextProps.form._id}`);
        }
    }

    render() {
        return (
            <Form {...this.props} />
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        loading: store.form.loading,
        form: store.form.form,
        errors: store.form.errors,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isLoading: (loading) => { dispatch(isLoading(loading)) },
        saveForm: (formData) => { dispatch(saveForm(formData)) },
        updateForm: (formData, id) => { dispatch(updateForm(formData, id)) },
        getForm: (id) => { dispatch(getForm(id)) },
        clearErrors: () => { dispatch(clearErrors()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);