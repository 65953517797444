import jwt_decode from 'jwt-decode';

import Api from '../services/api';

const namespace = 'AUTH';
export const LOGIN = `LOGIN_${namespace}`;
export const SET_TOKEN = `SET_TOKEN_${namespace}`;
export const IS_AUTH = `IS_AUTH_${namespace}`;
export const CLEAR = `CLEAR_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;


export const isLoading = () => dispatch =>{
    dispatch({
        type: IS_LOADING
    })
}

const decodeJWT = (jwt) => {
    return jwt_decode(jwt);
}

const parseToken = (token) => dispatch => {
    const decodedToken = decodeJWT(token);
    const { company, exp, lat, isAdmin, name, _id, dbName } = decodedToken;
    dispatch({
        type: LOGIN,
        company,
        exp,
        lat,
        isAdmin,
        name,
        _id,
        dbName
    })
}
const setJWT = (token) => {
    localStorage.setItem('JWT', token);
}

const destroyToken = () => {
    localStorage.removeItem('JWT');
}

export const logout = () => dispatch => {
    destroyToken();
    dispatch({
        type: CLEAR
    })
}

export const login = (login, password) => (dispatch) => {
    const form = { login, password }
    Api.post('/application/login', form).then(res => {
        if (res.success) {
            if (res.token) {
                setJWT(res.token);
                dispatch(isAuth());
            } else {
                console.error('No token returned from server')
            }
        } else {

        }
    }).catch(err => {
        console.error(err)
    })
}

export const isAuth = () => (dispatch, getState) => {
    const token = localStorage.getItem('JWT');
    dispatch(isLoading());
    Api.get('/application/isAuth').then(res => {
        if (res.success) {
            dispatch({
                type: IS_AUTH,
                isAuth: true
            })
            dispatch(isLoading());
            dispatch(parseToken(token));
        } else {
            dispatch({
                type: IS_AUTH,
                isAuth: false
            })
            destroyToken();
            dispatch(isLoading());
            dispatch({
                type: CLEAR
            })
        }
    }).catch(err => {
        dispatch(isLoading());
        console.error(err)
    })
}