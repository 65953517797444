import _ from 'lodash';
import { toast } from 'react-toastify';
import Api from '../services/api';

const namespace = 'RESOURCES';
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_RESOURCES = `SET_RESOURCES_${namespace}`;
export const CLEAR_RESOURCES = `CLEAR_RESOURCES_${namespace}`;
export const SET_PARAMS = `SET_PARAMS_${namespace}`;
export const CLEAR_PARAMS = `CLEAR_PARAMS_${namespace}`;

export const isLoading = (loading) => dispatch => {
    dispatch({
        type: IS_LOADING,
        loading
    });
}

export const setResources = (data) => (dispatch) => {
    dispatch({
        type: SET_RESOURCES,
        resources: data
    });
}

export const getResourcesByForm = (formId) => async (dispatch, getState) => {
    const { resources: { params } } = getState();
    let reqParams = JSON.parse(JSON.stringify(params));
    if (formId) {
        reqParams.form = formId;
    }
    dispatch(isLoading(true));

    dispatch(clearResources());
    let res = await Api.get("/resource/data", reqParams);
    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setResources(res.documents));
    } else {
        toast('Wystąpił błąd');
    }
}

export const clearResources = () => (dispatch) => {
    dispatch({
        type: CLEAR_RESOURCES
    });
}

export const deleteResource = (resource) => async (dispatch) => {
    let id = resource._id;
    dispatch(isLoading(true));

    let res = await Api.delete(`/resource/data/${id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast("Usunięto");
        dispatch(getResourcesByForm(resource.form));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setLanguagueFilter = (languague) => (dispatch, getState) => {
    const { resources: { params } } = getState();
    let shortName = _.get(languague, 'value.shortName');
    let formId = null;
    if (_.startsWith(window.location.pathname, "/resources/list/")) {
        formId = _.split(window.location.pathname, "/resources/list/").pop();
    }

    if (formId && shortName) {
        let newParams = JSON.parse(JSON.stringify(params));
        if(newParams.languague === shortName) {
            delete newParams.languague;
        } else {
            newParams.languague = shortName;
        }
        if (shortName) {
            dispatch({
                type: SET_PARAMS,
                params: newParams
            });
            dispatch(getResourcesByForm(formId));
        }
    }
}

export const clearParams = () => (dispatch) => {
    dispatch({
        type: CLEAR_PARAMS
    });
}


