import React from 'react';
import Tooltip from "react-simple-tooltip"
import { toast } from 'react-toastify';
import DeleteModal from './deleteFileModal';

import LibraryIcon from '../../assets/icons/icon--library.svg'
import FileIcon from '../../assets/icons/icon--filePlaceholder.svg'

export default class File extends React.Component {
    state = {
        showDropdown: false,
        deleteConfirmationModal: false,
        id: null
    }

    toggleDropdownMenu = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    renderDeleteFileModal = () => {
        if (this.state.deleteConfirmationModal) {
            return <DeleteModal
                handleDeleteFile={this.handleDeleteFile}
                hideDeleteModal={this.hideDeleteModal}
            />
        }
    }

    hideDeleteModal = () => {
        this.setState({
            id: null,
            deleteConfirmationModal: false
        })
    }

    handleDeleteFile = () => {
        const id = this.state.id;
        this.props.deleteFile(id);
        this.hideDeleteModal()
    }

    handleOpenDeleteModal = (id) => {
        this.setState({
            deleteConfirmationModal: true,
            id: id
        })
    }

    handleAddSingleFile = (file) => {
        this.props.addFileToForm(file);
        this.props.toggleLibrary()
    }

    handleCheckFile = (file) => {
        this.props.addFileToForm(file);
    }

    handleUncheckFile = (id) => {
        this.props.removeFileFromForm(id);
    }

    handleCopyLink = async (src) => {
        await navigator.clipboard.writeText(this.props.apiHost + src);
        toast("Skopiowano link");
    }

    renderDropdownItems = () => {
        const { canSelectMany, isOpenFromForm, isChecked, file } = this.props;

        return (
            <div>
                {isOpenFromForm && !isChecked &&  <a className="dropdown-item user-select-none" href="#!" onClick={() => this.handleAddSingleFile(file)}>Wybierz</a>}
                {/* {isChecked && <a className="dropdown-item user-select-none" href="#!" onClick={() => this.handleUncheckFile(file._id)}>Odznacz</a>} */}
                <a className="dropdown-item user-select-none" href="#!" onClick={() => this.handleOpenDeleteModal(file._id)}>Usuń</a>
                <a className="dropdown-item user-select-none" href="#!" onClick={() => this.handleCopyLink(file.src)}>Skopiuj link</a>
            </div>
        );
    }

    renderFileDropdownMenu = () => {
        return (
            <div className={this.state.showDropdown ? "cms-item__dropdown dropdown dropleft show" : "cms-item__dropdown dropdown dropleft"} onClick={this.toggleDropdownMenu}>
                <a href="#!" role="button" id="cms-item__dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showDropdown ? "true" : "false"}>
                    <img src={LibraryIcon} alt="" className="cms-item__icon" />
                </a>
                <div className={this.state.showDropdown ? "dropdown-menu show" : "dropdown-menu dropleft"} aria-labelledby="cms-item__dropdown">
                    {this.renderDropdownItems()}
                </div>
            </div>
        )
    }

    render() {
        const border = (this.props.isChecked) ? "3px solid green" : null;
        const avatar = (this.props.file.avatar) ?
            <img src={this.props.file.avatar} alt="" className="cms-file--img" style={{ border: border, boxSizing: 'border-box' }} /> :
            <div className="cms-file--img d-flex justify-content-center align-items-center" style={{ border: border, boxSizing: 'border-box' }}><img src={FileIcon} alt="" style={{ height: 50, }} /></div>;

        return (
            <div>
                {this.renderDeleteFileModal()}
                <div className="col-auto" id={this.props.file._id}  >
                    <div className="cms-file d-flex flex-column" >
                        {avatar}
                        <Tooltip content={this.props.file.name}>
                            <div className="cms-file--text">
                                <span className="cms-file--name">{this.props.file.name}</span>
                                {this.renderFileDropdownMenu(this.props.file)}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div >

        )
    }
}