import React, { Component } from 'react';
import PrimaryButton from '../buttons/primaryButton';
import SecondaryButton from '../buttons/secondaryButton';

export default class Subpage extends Component {

    constructor() {
        super();
        this.state = {
            showModal: false
        }
    }

    handleEditCick = () => {
        const { history, resource } = this.props;
        history.push(`/resources/data/${resource.resource}/${resource.languague}`);
    }

    handleShowModal = () => {
        this.setState({ showModal: true });
    }

    handleHideModal = () => {
        this.setState({ showModal: false });
    }

    handleDeleteClick = () => {
        const { deleteResource, resource } = this.props;
        deleteResource(resource);
    }

    renderModal = () => {
        const { showModal } = this.state;
        if(!showModal) {
            return null;
        }
        return (
            <div className="modal" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Usuwanie zasobu</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => this.handleHideModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Czy na pewno chcesz usunąć ten zasób?</p>
                        </div>
                        <div className="modal-footer">
                            <PrimaryButton
                                name="Usuń"
                                onPrimaryButtonClick={this.handleDeleteClick}
                            />
                            <SecondaryButton
                                onSecondaryButtonClick={this.handleHideModal}
                                name="Anuluj"
                            />
                        </div>
                    </div>
                </div>
            </div>);
    }

    render() {
        const { resource } = this.props;
        const modal = this.renderModal();

        return (
            <div>
                {modal}
                <div className="cms-multipage__item">
                    <h2 className="cms-multipage__item-title">[{resource.languague}] {resource.name}</h2>
                    <div className="cms-multipage__item-options">
                        <div className="cms-user__avatar">
                            <span className="cms-user__avatar-edited" style={{ width: "250px" }}>Dodane: {resource.createdAt}</span>
                            <span className="cms-user__avatar-name">DM</span>
                        </div>
                        <PrimaryButton
                            onPrimaryButtonClick={this.handleEditCick}
                            name="Edytuj"
                            icon="edit"
                            classes="mr-2"
                        />
                        <SecondaryButton name="Usuń" icon="delete" onSecondaryButtonClick={this.handleShowModal} />
                    </div>
                </div>
            </div>
        )
    }

}
