import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileInput from "../../containers/resource/file";
import PrimaryButton from "../buttons/primaryButton";
import Loader from "../loader/loader";
import GroupWrapper from "./group";
import RadioInput from "./radio";
import SelectInput from "./select";
import TextInput from "./text";
import DateInput from "./date";
import TextareaInput from "./textarea";

export default class Form extends Component {

    componentDidMount() {
        const { getForm, getResource, match, selectedForm, history } = this.props;
        let resourceId = _.get(match, 'params.id', null);
        let lang = _.get(match, 'params.lang', null);
        if (resourceId && lang) {
            getResource(resourceId, lang);
        } else if (selectedForm) {
            getForm(selectedForm._id);
        } else {
            toast("Nie wybrano formularza");
            history.push("/")
        }
    }

    setIsPrivate = (e) => {
        const { setIsPrivate } = this.props;
        setIsPrivate(e.target.checked);
    }

    renderSiteTitle = () => {
        const { form, isPrivate } = this.props;

        if (form) {
            return (
                <section className="cms-page-title">
                    <div className="container">
                        {form.multiple && <div className="row">
                            <div className="col-12 mb-5">
                                <Link to={`/resources/list/${form._id}`} className="btn-cms btn-cms--primary">Wstecz</Link>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-10">
                                <h1>{form.name}</h1>
                            </div>
                            <div className="col-2">
                                <div className="custom-control custom-switch d-flex justify-content-end">
                                    <input type="checkbox" className="custom-control-input" id="privateCheckbox" checked={isPrivate} onChange={(e) => this.setIsPrivate(e)} />
                                    <label className="custom-control-label" htmlFor="privateCheckbox"> Opublikuj </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>);
        }
        return null;
    }

    onInputChange = (path, value) => {
        const { onResourceChange } = this.props;
        onResourceChange(path, value);
    }

    addFields = (path) => {
        const { addFields } = this.props;
        addFields(path);
    }

    removeFields = (path, index) => {
        const { removeFields } = this.props;
        removeFields(path, index);
    }

    renderFields = (data, nested = false, path = null) => {
        const { form, errors } = this.props;
        let fields = [];
        if (data) {
            fields = data;
        } else if (form && form.fields) {
            fields = form.fields;
        }
        return fields.map((field, index) => {
            let fieldPath = path ? path + `[${field.groupIndex ? field.groupIndex : index}]` : `fields[${index}]`;
            //szukamy errora dla inputa o tej nazwie
            switch (field.type) {
                case 'text':
                    return <TextInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        wrap={!nested}
                        onChange={this.onInputChange}
                        value={field.value}
                        path={fieldPath}
                        errors={errors} />
                case 'date':
                    return <DateInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        wrap={!nested}
                        onChange={this.onInputChange}
                        value={field.value}
                        path={fieldPath}
                        errors={errors} />
                case 'textarea':
                    return <TextareaInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        wrap={!nested}
                        onChange={this.onInputChange}
                        value={field.value}
                        errors={errors}
                        path={fieldPath}
                        state={field.state} />;
                case 'select':
                    return <SelectInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        options={field.options}
                        wrap={!nested}
                        onChange={this.onInputChange}
                        value={field.value}
                        errors={errors}
                        path={fieldPath} />;
                case 'radio':
                    return <RadioInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        options={field.options}
                        wrap={!nested}
                        onChange={this.onInputChange}
                        value={field.value}
                        errors={errors}
                        path={fieldPath} />;
                case 'file':
                    return <FileInput
                        label={field.label}
                        placeholder={field.placeholder}
                        key={index}
                        name={field.name}
                        options={field.options}
                        wrap={!nested}
                        value={field.value}
                        errors={errors}
                        multiple={field.multiple}
                        path={fieldPath} />
                case 'group':
                    return <GroupWrapper
                        children={field.fields}
                        renderChildren={this.renderFields}
                        label={field.label}
                        key={index}
                        onChange={this.onInputChange}
                        fieldsLength={field.fieldsLength}
                        name={field.name}
                        addFields={this.addFields}
                        removeFields={this.removeFields}
                        path={fieldPath}
                        errors={errors} />;
                default:
                    return null;
            }
        });
    }

    handleSave = () => {
        const { saveResource, match, history } = this.props;
        let resourceId = _.get(match, 'params.id', null);
        saveResource(resourceId, history.push);
    }

    onNameChange = (name, value) => {
        const { changeName } = this.props;
        changeName(value);
    }

    renderNameInput = () => {
        const { name, errors } = this.props;
        return <TextInput
            label={"Nazwa zasobu"}
            placeholder={"Wpisz nazwę"}
            key={"name_input"}
            name={"name"}
            wrap={true}
            onChange={this.onNameChange}
            value={name}
            errors={errors}
            path={"name"} />;
    }

    render() {
        const { loading } = this.props;
        const title = this.renderSiteTitle();
        const fields = this.renderFields();
        const nameInput = this.renderNameInput();

        return (
            <div>
                {title}
                <div className="cms-items">
                    {loading && (<div className="container text-center">
                        <div className="row">
                            <div className="col-12">
                                <Loader />
                            </div>
                        </div>
                    </div>)}
                    <div className={`container ${loading ? "d-none" : ""}`}>
                        <div className="row">
                            {nameInput}
                            {fields}
                            <div className="col-12 mt-3 text-right">
                                <PrimaryButton icon="submit" name="Zapisz" onPrimaryButtonClick={this.handleSave} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        const { clearAll } = this.props;
        clearAll();
    }

}