import React from 'react';
import { connect } from 'react-redux';
import {
    addFileToForm,
    addFileToUpload,
    changeOffset,
    clearFilesToUpload,
    deleteFile,
    deleteFileFromPreview,
    getFiles,
    isLoading,
    removeFileFromForm,
    toggleLibrary,
    uploadFiles,
    clearOffset
} from '../actions/library';
import Library from '../components/library/library';
import Api from '../services/api';



class LibraryContainer extends React.Component {
    render() {
        return (
            <Library {...this.props} />
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        library: store.library,
        offset: store.library.offset,
        total: store.library.total,
        files: store.library.files,
        apiHost: Api.getUrl()
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isLoading: () => {
            dispatch(isLoading())
        },
        toggleLibrary: (isOpenFromForm, inputName, canSelectMany) => {
            dispatch(toggleLibrary(isOpenFromForm, inputName, canSelectMany))
        },
        addFileToUpload: (file) => {
            dispatch(addFileToUpload(file))
        },
        addFileToForm: (id) => {
            dispatch(addFileToForm(id))
        },
        removeFileFromForm: (id) => {
            dispatch(removeFileFromForm(id))
        },
        deleteFileFromPreview: (i) => {
            dispatch(deleteFileFromPreview(i))
        },
        uploadFiles: () => {
            dispatch(uploadFiles())
        },
        clearFilesToUpload: () => {
            dispatch(clearFilesToUpload())
        },
        getFiles: () => {
            dispatch(getFiles())
        },
        deleteFile: (id) => {
            dispatch(deleteFile(id))
        },
        changeOffset: () => {
            dispatch(changeOffset());
        },
        clearOffset: () => {
            dispatch(clearOffset());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LibraryContainer);