import React from 'react';
//ikonki dorzucac
import EditIcon from '../../assets/icons/icon--edit.svg';
import SubmitIcon from '../../assets/icons/icon--plus--white.svg';

class PrimaryButton extends React.Component {
    setIcon = () => {
        let icon = null;

        switch (this.props.icon) {
            case 'edit': {
                icon = EditIcon;
                break;
            }
            case 'submit': {
                icon = SubmitIcon;
                break;
            }
            default: {
                break;
            }
        }

        if (icon) {
            return (
                <span className="icon mr-4">
                    <img src={icon} alt="Icon Button" />
                </span>
            )
        }
    }

    render() {
        const { classes, name } = this.props;
        const icon = this.setIcon();

        return (
            <button
                className={`btn-cms btn-cms--primary ${classes ? classes : ""}`}
                onClick={() => this.props.onPrimaryButtonClick()}
            >
                {icon}
                {name}
            </button>
        )
    }
}

export default PrimaryButton;