import React, { Component } from 'react';

import DeleteIcon from '../../assets/icons/icon--delete.svg'
import AddIcon from '../../assets/icons/icon--plus.svg'
import LibraryIcon from '../../assets/icons/icon--library.svg'

export default class SecondaryButton extends Component {
    setIcon = () => {
        let icon = null;

        switch (this.props.icon) {
            case 'delete': {
                icon = DeleteIcon;
                break;
            }
            case 'add': {
                icon = AddIcon;
                break;
            }
            case 'library': {
                icon = LibraryIcon;
                break;
            }
            default: {
                break;
            }
        }

        if (icon) {
            return (
                <span className="icon mr-4">
                    <img src={icon} alt="Icon Button" />
                </span>
            )
        }
    }

    render() {
        const classes = this.props.icon === 'library' ? "btn-cms btn-cms--secondary cms-multipage__item-edit mr-4" : "btn-cms btn-cms--secondary cms-multipage__item-edit mr-4";

        return (
            <button
                className={classes}
                onClick={() => this.props.onSecondaryButtonClick()}
            >
                {this.setIcon()}
                {this.props.name}
            </button>
        )
    }
}