import { toast } from 'react-toastify';
import Api from '../services/api';

const namespace = 'FORMS';
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_FORMS = `SET_FORMS_${namespace}`;
export const CLEAR_FORMS = `CLEAR_FORMS_${namespace}`;
export const SET_SELECTED_FORM = `SET_SELECTED_FORM_${namespace}`;
export const CLEAR_SELECTED_FORM = `CLEAR_SELECTED_FORM_${namespace}`;

export const isLoading = (loading) => dispatch => {
    dispatch({
        type: IS_LOADING,
        loading
    });
}

export const setForms = (data) => (dispatch) => {
    dispatch({
        type: SET_FORMS,
        forms: data
    });
}

export const getForms = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/resource/resource`);
    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setForms(res.documents));
    } else {
        toast('Wystąpił błąd');
    }
}

export const clearForms = () => (dispatch) => {
    dispatch({
        type: CLEAR_FORMS
    });
}

export const setSelectedForm = (form) => (dispatch) => {
    dispatch({
        type: SET_SELECTED_FORM,
        form
    });
}

export const clearSelectedForm = () => (dispatch) => {
    dispatch({
        type: CLEAR_SELECTED_FORM
    });
}



