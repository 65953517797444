import { zipWith, values, join, keys } from 'lodash';
import { toast } from 'react-toastify';
import {logout} from '../actions/auth';
import {store} from '../index';

class Api {

    constructor() {
        this.url = "https://api-cmsly.overcloud.usermd.net";
        // this.url = "http://10.1.1.107.:3001";
        // this.url = "http://localhost:3001";
    }

    getUrl() {
        return this.url;
    }

    put(route, params) {
        return this.api(route, params, 'PUT', true);
    }

    delete(route, params) {
        return this.api(route, params, 'DELETE', true);
    }

    post(route, params, auth, file) {
        return this.api(route, params, 'POST', auth, file);
    }

    get(route, params, responseType) {
        if (params) {
            params = zipWith(keys(params), values(params), (key, val) => {
                return `${key}=${val}`
            });
            route += '?' + join(params, '&');
        }
        return this.api(route, null, 'GET', true, null, responseType);
    }

    api(route, params, method, auth = true, file, responseType = 'json') {
        let headers = null;
        let token = localStorage.getItem('JWT');
        if (auth && !file) headers =
            {
                'Content-Type': 'application/json',
                'authorization': `JWT:${token}`
            }
        else if (!auth && !file) headers = {
            'Content-Type': 'application/json'
        }
        else headers = {
            'authorization': `JWT:${token}`
        }
        params = params ? params : null;
        const options = Object.assign({ method: method }, { headers: headers }, params ? { body: file ? params : JSON.stringify(params) } : null);
        route = `${this.url}${route}`;
        try {
            return fetch(route, options).then(res => {
                switch (res.status) {
                    case 401: {
                        store.dispatch(logout());
                        toast("Brak autoryzacji");
                        return res.json();
                    }
                    case 403: {
                        toast("Brak uprawnień");
                        return res.json();
                    }
                    case 404: {
                        toast("Nie znaleziono zasobu");
                        return res.json();
                    }
                    case 500: {
                        toast("Błąd systemu");
                        return res.json();
                    }
                    default: {
                        if (responseType === 'json') {
                            return res.json();
                        } else {
                            return res.text();
                        }
                    }
                }
            });
        } catch (error) {
            console.error(error)
        }
    }
}

export default new Api();