import produce from "immer";

import {
    TOGGLE_LIBRARY,
    ADD_FILE_TO_UPLOAD,
    CLEAR_FILES_TO_UPLOAD,
    SET_FILES,
    DELETE_FILE_FROM_PREVIEW,
    IS_LOADING,
    INSERT_INTO_SELECTED_FILES,
    REMOVE_FROM_SELECTED_FILES,
    SET_INPUT_NAME,
    CLEAR_INPUT_NAME,
    CLEAR_SELECTED_FILES,
    SET_OFFSET,
    CLEAR_OFFSET
} from '../actions/library';

const libraryDefaultState = {
    libraryVisibility: false,
    isOpenFromForm: true,
    loading: false,
    filesToUpload: [],
    files: [],
    selectedFiles: [],
    inputName: null,
    canSelectMany: true,
    offset: 0,
    total: 0
}

const libraryReducer = (state = libraryDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING:
                draft.loading = !draft.loading;
                break;
            case TOGGLE_LIBRARY: {
                draft.libraryVisibility = !draft.libraryVisibility;
                draft.isOpenFromForm = action.isOpenFromForm;
                draft.inputName = action.inputName;
                draft.canSelectMany = action.canSelectMany
                break;
            }
            case ADD_FILE_TO_UPLOAD:
                draft.filesToUpload.push(action.file);
                break;
            case DELETE_FILE_FROM_PREVIEW:
                draft.filesToUpload.splice(action.i, 1)
                break;
            case CLEAR_FILES_TO_UPLOAD:
                draft.filesToUpload = [];
                break;
            case SET_FILES:
                draft.files = action.files;
                draft.total = action.total;
                break;
            case INSERT_INTO_SELECTED_FILES:
                draft.selectedFiles = action.selectedFiles;
                break;
            case REMOVE_FROM_SELECTED_FILES:
                draft.selectedFiles[action.inputName].splice(action.i, 1);
                break;
            case SET_INPUT_NAME:
                draft.inputName = action.name;
                break;
            case CLEAR_INPUT_NAME:
                draft.inputName = null;
                break;
            case CLEAR_SELECTED_FILES: {
                draft.selectedFiles = [];
                break;
            }
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            case CLEAR_OFFSET: {
                draft.offset = 0;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default libraryReducer;