import React from 'react';
import { connect } from 'react-redux';
import FileInput from '../../components/resource/file';
import { toggleLibrary } from '../../actions/library';
import { deleteFileFromForm } from '../../actions/resource';

class FileInputContainerContainer extends React.Component {
    render() {
        return (
            <FileInput {...this.props} />
        )
    }
}

function mapStateToProps(store) {
    return {
        selected: store.library.selectedFiles
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleLibrary: (isOpenFromForm, inputName, canSelectMany) => {
            dispatch(toggleLibrary(isOpenFromForm, inputName, canSelectMany))
        },
        deleteFileFromForm: (path, value) => {
            dispatch(deleteFileFromForm(path, value));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileInputContainerContainer);