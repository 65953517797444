import produce from "immer";

import {
    SET_LANGUAGES,
    SET_ERRORS,
    CLEAR_ERRORS
} from '../actions/header';

const headerDefaultState = {
    languages: null,
    error: null
}

const headerReducer = (state = headerDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_LANGUAGES: {
                draft.languages = action.languages;
                break;
            }
            case SET_ERRORS:{
                draft.error = action.error;
                break;
            }
            case CLEAR_ERRORS:{
                draft.error = null;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default headerReducer;