import produce from "immer";

import { IS_LOADING, SET_FORM, CLEAR_ITEMS, SET_ERRORS, CLEAR_ERRORS } from '../actions/form';

const initial_state = {
    loading: false,
    form: null,
    errors: {}
}

const formReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING:
                draft.loading = action.loading;
                break;
            case SET_FORM:
                draft.form = action.form;
                break;
            case CLEAR_ITEMS:
                draft.form = {
                    name: '',
                    private: false,
                    multiple: false,
                    fields: []
                };
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            default: {
                return draft;
            }
        }
    });

}

export default formReducer;