import React from 'react';
import './modal.css';

import DeleteModal from './deleteSettingsModal';
import BasicInput from '../inputs/basicInput';
import PrimaryButton from '../buttons/primaryButton';
import SecondaryButton from '../buttons/secondaryButton';

class AddLanguageModal extends React.Component {
    state={
        deleteConfirmationModal: false
    }

    renderModalTitle = () => {
        if (this.props.langId) {
            return "Edycja Języka";
        }
        return "Dodawanie Języka";
    }

    renderSaveButton = () => {
        if (this.props.langId) {
            return (
                <PrimaryButton
                    onPrimaryButtonClick={this.props.handleSubmitAddLanguage}
                    name="Zapisz"
                    icon="submit"
                />
            )
        } else {
            return (
                <PrimaryButton
                    onPrimaryButtonClick={this.props.handleSubmitAddLanguage}
                    name="Utwórz"
                    icon="submit"
                />
            )
        }
    }

    toggleDeleteModal = () =>{
        this.setState({
            deleteConfirmationModal: !this.state.deleteConfirmationModal
        })
    }

    renderDeleteButton = () => {
        if (this.props.langId) {
            return (
                <SecondaryButton
                    onSecondaryButtonClick={this.toggleDeleteModal}
                    name="Usuń"
                    icon="delete"
                />
            )
        }
    }

    renderDeleteFileModal = () => {
        if (this.state.deleteConfirmationModal) {
            return <DeleteModal
                handleDeleteLanguage={this.props.handleDeleteLanguage}
                hideDeleteModal={this.toggleDeleteModal}
            />
        }
    }

    render() {
        return (
            <div>
                {this.renderDeleteFileModal()}
                <div className="modal" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">{this.renderModalTitle()}</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.toggleAddLanguageModalVisibility}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <BasicInput
                                    label="Pełna nazwa języka"
                                    type="text"
                                    id="fullName"
                                    onChange={this.props.handleInputChange}
                                    defaultValue={this.props.fullName}
                                    error={this.props.error}
                                />
                                <BasicInput
                                    label="Skrócona nazwa języka"
                                    type="text"
                                    id="shortName"
                                    onChange={this.props.handleInputChange}
                                    defaultValue={this.props.shortName}
                                    error={this.props.error}
                                />
                            </div>
                            <div className="modal-footer">
                                {this.renderDeleteButton()}
                                {this.renderSaveButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default AddLanguageModal;