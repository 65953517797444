import React from 'react';
import { connect } from 'react-redux';
import {
    clearErrors,
    clearForm,
    getForm,
    getResource,
    isLoading,
    loadSelectedForm,
    onResourceChange,
    saveResource,
    setForm,
    changeName,
    addFields,
    removeFields,
    clearAll,
    setIsPrivate
} from '../../actions/resource';
import { getFiles } from '../../actions/library';
import Form from '../../components/resource/form';

class FormContainer extends React.Component {
    render() {
        return (
            <Form {...this.props} />
        )
    }
}

function mapStateToProps(store) {
    return {
        loading: store.resource.loading,
        form: store.resource.form,
        errors: store.resource.errors,
        name: store.resource.name,
        isPrivate: store.resource.private,
        selectedForm: store.forms.selectedForm,
        store: store
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isLoading: (loading) => {
            dispatch(isLoading(loading))
        },
        setForm: (data) => {
            dispatch(setForm(data));
        },
        getForm: (id) => {
            dispatch(getForm(id));
        },
        clearForm: () => {
            dispatch(clearForm());
        },
        getResource: (id, lang) => {
            dispatch(getResource(id, lang));
        },
        saveResource: (resourceId, push) => {
            dispatch(saveResource(resourceId, push));
        },
        onResourceChange: (path, value) => {
            dispatch(onResourceChange(path, value));
        },
        clearErrors: () => {
            dispatch(clearErrors());
        },
        getFiles: () => {
            dispatch(getFiles());
        },
        loadSelectedForm: () => {
            dispatch(loadSelectedForm());
        },
        changeName: (name) => {
            dispatch(changeName(name));
        },
        addFields: (path) => {
            dispatch(addFields(path));
        },
        removeFields: (path, index) => {
            dispatch(removeFields(path, index));
        },
        clearAll: () => {
            dispatch(clearAll());
        },
        setIsPrivate: (isPrivate) => {
            dispatch(setIsPrivate(isPrivate));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);