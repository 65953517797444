import produce from "immer";

const testDefaultState = {
    test: false
}

const testReducer = (state = testDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case 'TEST_ACTION': {
                console.log('testowa akcja')
                draft.test = !draft.test;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default testReducer;