import React, { Component } from 'react';
import ItemTitleInput from '../inputs/itemTitleInput';

import SecondaryButton from '../buttons/secondaryButton';
import BasicInput from '../inputs/basicInput';

export default class SelectionInputField extends Component {

    state = {
        nameError: null,
        labelError: null,
        placeholderError: null,
        optionsError: null,
        optionError: null
    }

    renderError = () => {
        let error = this.state.optionError;

        if (error) return <div><span className="text-danger">{error}</span></div>
        return null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors !== this.props.errors) {
            this.setState({ nameError: null, labelError: null, placeholderError: null, optionsError: null, optionError: null })

            nextProps.errors.forEach(error => {
                switch (error.field) {
                    case 'label':
                        this.setState({ labelError: error.error })
                        break;
                    case 'name':
                        this.setState({ nameError: error.error })
                        break;
                    case 'placeholder':
                        this.setState({ placeholderError: error.error })
                        break;
                    case 'options':
                        if (Array.isArray(error.error)) this.setState({ optionsError: error.error })
                        else this.setState({ optionError: error.error })
                        break;
                    default: return;
                }
            })
        }
    }

    renderOptions = () => {
        const options = this.props.options;
        const optionsError = this.state.optionsError;

        return options.map((option, index) => {
            let newError = null;

            optionsError ? optionsError.forEach(optionError => {
                if (optionError.index === index) newError = optionError.error;
            }) : newError = null;

            return <BasicInput
                defaultValue={option.label}
                error={newError}
                onChange={(e) => this.onChange(e, index)}
            />
        })
    }

    onChange = (e, optionIndex) => {
        let options = this.props.options;

        options.map((option, index) => {
            if (index === optionIndex) {
                option.value = e.target.value;
                option.label = e.target.value;
            }
            return option;
        });

        this.props.onChange(options, 'options');
    }

    addOptions = () => {
        const options = this.props.options;
        options.push({ label: '', value: '' });

        this.setState({ options: options });
    }

    render() {
        return (
            <div className="cms-group">
                <div className="cms-group__content">
                    <div className="col-12">
                        <div className="cms-item cms-list">
                            <ItemTitleInput
                                icon={this.props.icon}
                                placeholder={this.props.namePlaceholder}
                                name={this.props.name}
                                multiple={this.props.multiple}
                                required={this.props.required}
                                switchFieldName={'select' + Date.now()}
                                isRequiredFieldName={this.props.isRequiredFieldName}
                                error={this.state.nameError}
                                id={this.props.id}
                                onChange={(e, name) => this.props.onChange(e, name)}
                            />
                            <div className="cms-item__body">
                                <BasicInput
                                    type="text"
                                    label="Label"
                                    defaultValue={this.props.label}
                                    placeholder="Wpisz treść labela"
                                    error={this.state.labelError}
                                    onChange={(e) => this.props.onChange(e, 'label')}
                                />
                                {this.props.type !== 'radio' ? <BasicInput
                                    type="text"
                                    label="Placeholder"
                                    defaultValue={this.props.placeholder}
                                    placeholder="Wpisz treść placeholdera"
                                    error={this.state.placeholderError}
                                    onChange={(e) => this.props.onChange(e, 'placeholder')}
                                /> : null}
                                <br />
                                {this.renderOptions()}
                                <br />
                                <SecondaryButton
                                    icon="add"
                                    name="Dodaj następną pozycję"
                                    onSecondaryButtonClick={this.addOptions}
                                />
                                {this.renderError()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}