import { combineReducers } from 'redux';
import testReducer from '../reducers/testReducer';
import authReducer from '../reducers/authReducer';
import headerReducer from '../reducers/headerReducer';
import libraryReducer from '../reducers/libraryReducer';
import resourceReducer from "../reducers/resourceReducer";
import formsReducer from "../reducers/formsReducer";
import resourcesReducer from "../reducers/resourcesReducer";
import formReducer from '../reducers/formReducer';

export default combineReducers({
    test: testReducer,
    auth: authReducer,
    header: headerReducer,
    library: libraryReducer,
    resource: resourceReducer,
    forms: formsReducer,
    resources: resourcesReducer,
    form: formReducer
})