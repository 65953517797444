import React from 'react';
import Dropzone from 'react-dropzone';

import imageCompression from 'browser-image-compression';

export default class FileUploader extends React.Component {

    processFiles = (acceptedFiles, props) => {
        acceptedFiles.forEach((file, i) => {
            props.isLoading();
            let fileInfo = {};

            const reader = new FileReader()
            // reader.onabort = () => console.log('file reading was aborted')
            // reader.onprogress = () => console.log('Reading')
            reader.onerror = () => console.error('Error while processing file')
            reader.onload = () => {
                fileInfo.file = file;

                var options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 250,
                    useWebWorker: true
                }

                if (file.type.startsWith('image')) {
                    imageCompression(file, options)
                        .then(function (compressedFile) {
                            imageCompression.getDataUrlFromFile(compressedFile).then((data) => {
                                fileInfo.avatar = data;
                                props.addFileToUpload(fileInfo);
                                props.isLoading();
                            })
                        })
                } else {
                    props.addFileToUpload(fileInfo);
                    props.isLoading();
                }
            }
            reader.readAsArrayBuffer(file);
        })
    }

    render() {

        const baseStyle = {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '3rem',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            margin: '20px',
            minHeight: '200px'
        };

        return (
            <Dropzone onDrop={(acceptedFiles) => {
                this.processFiles(acceptedFiles, this.props);
            }}
            >
                {({ getRootProps, getInputProps }) => (
                    <section style={baseStyle}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Przeciągnij plik i upuść bądź kliknij by dodać pliki</p>
                        </div>
                    </section>
                )}
            </Dropzone>
        )
    }
}
