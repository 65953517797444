import React from 'react';
import { Redirect } from 'react-router-dom';
import AddInputs from '../addInputs';
import PrimaryButton from '../buttons/primaryButton';

import TextInputField from './textInputField';
import DateInputField from './dateInputField';
import SelectionInputField from './selectionInputField.js';
import FileInputField from './fileInputField.js';
import GroupInputField from './groupInputField';
import PageTitleInput from '../inputs/pageTitleInput';

import Loader from '../loader/loader';
import _ from 'lodash';

class Form extends React.Component {

    state = {
        items: [],
        fieldId: 0,
        form: {
            name: '',
            private: false,
            multiple: false,
            fields: []
        }
    }

    componentWillMount() {
        const { match, getForm } = this.props;
        let formId = _.get(match, 'params.id', null);
        if (formId) getForm(formId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.form !== this.props.form) {
            const { clearErrors } = this.props;
            clearErrors();

            this.setState({ items: [], fieldId: 0 })
            if (nextProps.form && nextProps.form.fields) this.parseItems(nextProps.form);

            setTimeout(() => {
                this.setState({ items: nextProps.form.fields ? nextProps.form.fields : [], form: nextProps.form ? nextProps.form : {} });
            }, 1);
        }
    }

    // set ancestors and path when editing form
    parseItems = (form, ancestors) => {
        return form.fields.map((item, index) => {
            if (!ancestors) this.increaseFieldIdHandler();

            if (!ancestors) {
                item.ancestors = [index];
                item.fieldId = index;
                return this.parseItems(item, item.ancestors);
            } else {
                item.ancestors = ancestors;

                item.path = '';
                item.ancestors.map((position) => {
                    item.path += `[${position}].fields`;
                });
            }

            if (item.type === 'group') {
                const newAncestors = [...item.ancestors];
                newAncestors.push(index);

                return this.parseItems(item, newAncestors);
            }

            return item;
        })
    }

    onItemsClickedHandler = (newItems) => {
        let form = this.state.form;
        form.fields = newItems;
        this.setState({ items: newItems, form: form });
    }

    increaseFieldIdHandler = () => {
        this.setState(prevState => {
            return { fieldId: prevState.fieldId + 1 }
        })
    }

    onChange = (value, field, path, ancestors, itemIndex) => {
        let items = [...this.state.items];

        if (field === 'delete') {
            const { clearErrors } = this.props;
            this.setState({ fieldId: 0 })
            clearErrors();

            if (path) _.get(items, path).splice(itemIndex, 1);
            else items.splice(ancestors, 1);

            items.map((item, index) => {
                this.increaseFieldIdHandler();
                item.fieldId = index;
            })

        } else {
            let itemPath = path ? path += `[${itemIndex}]` : ancestors;
            const currentValue = _.get(items, itemPath);

            if (currentValue) {
                if (field === 'multiple') value = !currentValue.multiple
                if (field === 'required') value = !currentValue.required
                _.set(items, itemPath += '.' + field, field === 'options' || field === 'multiple' || field === 'required' ? value : value.target.value);
            }
        }

        this.setState({ items: items });
    }

    getItemErrors = (errors, item, itemIndex) => {
        const fields = ['label', 'placeholder', 'name', 'options', 'fields'];
        const itemErrors = [];

        fields.forEach(field => {
            let path = item.path ? item.path + `[${itemIndex}]` + '.' + field : `[${item.fieldId}]` + '.' + field;
            let error = _.get(errors, path, null);

            if (field === 'options') {
                const optionsError = [];

                if (item.options) item.options.forEach((option, index) => {
                    const optionsPath = path + `[${index}]` + '.label';
                    let optionError = _.get(errors, optionsPath, null);

                    if (optionError) optionsError.push({ field: field, error: optionError, index: index });
                })

                if (optionsError.length > 0) itemErrors.push({ field: field, error: optionsError });
            }

            if (error) itemErrors.push({ field: field, error: error });
        })

        return itemErrors;
    }

    setItems = (input, index, ancestors) => {
        const { errors, auth: { isAdmin } } = this.props;
        const tmpInput = input.type === 'group' ? input.fields : [...[input]]
        //resources/resource/5d4441a03f3f8e78e57ba49c
        if (!isAdmin) {
            return <Redirect to="/" />
        }

        return tmpInput.map((item, itemIndex) => {
            const newAncestors = item.ancestors ? [...item.ancestors] : [index];
            const error = this.getItemErrors(errors, item, itemIndex);
            const timestamp = Date.now();

            if (item.type === 'textarea') {
                return <TextInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    icon={item.type}
                    required={item.required}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Obszar tekstowy"
                    errors={error}
                />
            }
            if (item.type === 'text') {
                return <TextInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    icon={item.type}
                    required={item.required}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Pole tekstowe"
                    errors={error}
                />
            }
            if (item.type === 'date') {
                return <DateInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    icon={item.type}
                    required={item.required}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Pole daty"
                    errors={error}
                />
            }
            if (item.type === 'select') {
                return <SelectionInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    placeholder={item.placeholder}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    icon={item.type}
                    options={item.options}
                    multiple={item.multiple}
                    required={item.required}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Lista wyboru"
                    errors={error}
                />
            }
            if (item.type === 'radio') {
                return <SelectionInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    icon={item.type}
                    options={item.options}
                    required={item.required}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Pole wyboru"
                    errors={error}
                />
            }
            if (item.type === 'file') {
                return <FileInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    icon={item.type}
                    multiple={item.multiple}
                    required={item.required}
                    isRequiredFieldName={item.path ? item.path + item.type + itemIndex + timestamp : item.fieldId + item.type + itemIndex + timestamp}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    namePlaceholder="Plik"
                    toggleLibrary={this.props.toggleLibrary}
                    errors={error}
                />
            }
            if (item.type === 'group') {
                newAncestors.push(itemIndex);

                return <GroupInputField
                    key={index}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    components={this.setItems(item, itemIndex)}
                    id={item._id}
                    onChange={(e, field) => this.onChange(e, field, item.path, item.ancestors, itemIndex)}
                    errors={error}
                    AddInputs={<AddInputs
                        items={this.state.items}
                        onItemsClicked={this.onItemsClickedHandler}
                        fieldId={item.fieldId}
                        ancestors={newAncestors}
                    />}
                />
            }
        }).reduce((arr, element) => {
            return arr.concat(element);
        }, []);
    }

    renderGroup = (input, index) => {
        const { errors } = this.props;
        const error = this.getItemErrors(errors, input, index);

        return <GroupInputField
            key={index}
            type={input.type}
            name={input.name}
            label={input.label}
            id={input._id}
            onChange={(e, field) => this.onChange(e, field, input.path, input.ancestors, index)}
            components={this.setItems(input, index)}
            errors={error}
            AddInputs={<AddInputs
                items={this.state.items}
                onItemsClicked={this.onItemsClickedHandler}
                fieldId={input.fieldId}
                ancestors={[index]}
            />}
        />
    }

    renderItems = () => {
        return this.state.items
            .map((item, index) => {
                if (item.type === 'group') {
                    return <section className="cms-items" key={index}>
                        {this.renderGroup(item, index)}
                    </section>
                } else {
                    return <section className="cms-items" key={index}>
                        {this.setItems(item, index, item.ancestors)}
                    </section>
                }
            })
    }

    onChangeValue = (e) => {
        let form = this.state.form;
        form.name = e.target.value;
        this.setState({ form: form });
    }

    onChangeMultipleHandler = () => {
        let form = this.state.form;
        form.multiple = !this.state.form.multiple;
        this.setState({ form: form });
    }

    handleSaveButtonClick = () => {
        const { match } = this.props;
        let formData = this.state.form;
        formData.fields = [...this.state.items];

        this.setState({ fieldId: 0 })

        formData.fields.map((item, index) => {
            this.increaseFieldIdHandler();
            item.fieldId = index;
        })

        let formId = _.get(match, 'params.id', null);
        if (formId) this.props.updateForm(formData, formId);
        else this.props.saveForm(formData);

    }

    renderError = () => {
        const { errors } = this.props;

        if (errors.fields) {
            return <div><span className="text-danger">{errors.fields}</span></div>
        }

        return null
    }

    render() {
        const { loading, errors, match } = this.props;
        let formId = _.get(match, 'params.id', null);

        return (
            <div>
                {loading && (<section className="cms-items">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-12">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </section>)}
                <div className={`container ${loading ? "d-none" : ""} `}>
                    <PageTitleInput
                        id={formId ? formId : null}
                        type="text"
                        placeholder="Wpisz tytuł strony..."
                        value={this.state.form.name}
                        defaultValue={this.state.form.multiple}
                        onChange={this.onChangeValue}
                        error={errors && errors.name ? errors.name : null}
                        onChangeMultiple={this.onChangeMultipleHandler}
                    />
                    <section className="cms-items">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {this.renderError()}
                                    {this.renderItems()}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="cms-add" onClick={this.increaseFieldIdHandler}>
                        <AddInputs
                            items={this.state.items}
                            onItemsClicked={this.onItemsClickedHandler}
                            fieldId={this.state.fieldId}
                        />
                    </section>

                    <section className="cms-add">
                        <PrimaryButton
                            icon="none"
                            name="Zapisz"
                            onPrimaryButtonClick={this.handleSaveButtonClick}
                        />
                    </section>

                </div>
            </div>
        )
    }

}

export default Form;