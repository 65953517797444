import _ from 'lodash';
import React, { Component } from 'react';
import SecondaryButton from '../buttons/secondaryButton';
import FileCard from "./fileCard";
import Api from "../../services/api";

export default class FileInput extends Component {

    handleOpenLibrary = () => {
        const { toggleLibrary, multiple, path } = this.props;
        toggleLibrary(true, path, multiple);
    }

    handleDeleteFile = (path, value) => {
        const { deleteFileFromForm } = this.props;
        deleteFileFromForm(path, value);
    }

    renderSelectedFiles = () => {
        const { path, value } = this.props;
        if (value) {
            return _.castArray(value).map((src, index) => {
                let name = _.split(src, "/").pop();
                return <FileCard src={Api.getUrl() + src} shortSrc={src} name={name} key={`${path}_${index}`} path={path} handleDelete={this.handleDeleteFile} />
            });
        }
        return null;
    }

    renderError = () => {
        const { errors, path } = this.props;
        if (errors && errors[path]) {
            return <div className="col-12"><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    render() {
        const { label, wrap } = this.props;
        const selected = this.renderSelectedFiles();
        const errorText = this.renderError();

        return (<div className="col-12 mt-3">
            <div className={wrap ? "cms-group" : ""}>
                <div className="cms-group__content">
                    <div className="cms-item cms-files">
                        <div className="cms-item__header">
                            <div className="cms-item__header--left">
                                <h1>{label}</h1>
                            </div>
                            <div className="cms-item__header--right">
                                <SecondaryButton
                                    name="Wybierz z biblioteki"
                                    icon="library"
                                    onSecondaryButtonClick={this.handleOpenLibrary}
                                />
                            </div>
                        </div>
                        <div className="cms-item__body">
                            <div className="cms-item--files">
                                <div className="row">
                                    {selected}
                                    {errorText}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}