import _ from 'lodash';
import React, { Component } from 'react';
import PlusIcon from '../../assets/icons/icon--plus.svg';

export default class GroupWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }

    //Zmiana strony na pierwszą jeśli następna grupa nie ma tylu pól ile poprzednia
    componentWillReceiveProps({ children }) {
        const { page } = this.state;
        const { fieldsLength } = this.props;
        if (page >= _.size(_.chunk(children, fieldsLength))) {
            this.setState({ page: 0 });
        }
    }

    //Funkcja dodaje nową grupę pól w ilości tylu ile było oryginalnie w polu o typie grupa - parametr fieldsLength ustawiany przy zapisie pola w api
    addFields = () => {
        const { path, addFields } = this.props;
        addFields(path);
    }

    removeFields = () => {
        const { page } = this.state;
        const { path, removeFields } = this.props;
        if (page > 0) {
            removeFields(path, page);

        }
    }

    //Funkcja zwraca tablice pól z grupy w zależności od wybranej strony i oryginalnej długości tablicy pól - czyli ile było pól w grupie przed dodaniem nowych (te pola kopiujemy) 
    getChildrenChunk = () => {
        const { page } = this.state;
        const { children, fieldsLength } = this.props;
        //Group index jest po to żeby onChange zmienił wartość w dobrym inpucie, przy przełączaniu strony w grupie w mapie zawsze mamy indexy od 0 mimo że pole może mieć index 3
        let indexed = children.map((child, index) => {
            child.groupIndex = index;
            return child;
        });
        let chunk = _.chunk(indexed, fieldsLength)[page];
        return chunk || [];
    }

    //Funkcja zmienia stronę na podaną
    changePage = (page) => {
        const { children, fieldsLength } = this.props;

        let nr_of_pages = _.size(_.chunk(children, fieldsLength));
        if (page >= 0 && page <= nr_of_pages - 1) {
            this.setState({ page: page });
        }
    }

    //Funkcja renderuje toolbar paginacji w grupie
    renderPagination = () => {
        const { page } = this.state;
        const { children, fieldsLength } = this.props;
        let nr_of_pages = _.size(_.chunk(children, fieldsLength));
        const childHasError = this.checkChildHasError();

        return (<div className={`cms-group__pagination ${childHasError ? "text-danger" : ""}`}>
            <a href="#!" className={`cms-group__prev ${page === 0 ? 'disabled' : ''}`} onClick={() => this.changePage(page - 1)}>{"<"}</a>
            <span className="cms-group__page-number">{page + 1}</span>
            <span>z</span>
            <span className="cms-group__page-amount">{nr_of_pages}</span>
            <a href="#!" className={`cms-group__next ${(page + 1) === nr_of_pages ? 'disabled' : ''}`} onClick={() => this.changePage(page + 1)}>{">"}</a>
        </div>);
    }

    getChildrenPath = () => {
        const { path } = this.props;
        return path + ".fields";
    }

    renderError = () => {
        const { errors, path } = this.props;

        if (errors && errors[path]) {
            return <div className="mt-3"><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    checkChildHasError = () => {
        const { errors, path } = this.props;
        let has = false;
        _.each(errors, (val, key) => {
            if (_.startsWith(key, path)) {
                has = true;
            }
        });
        return has;
    }

    render() {
        const { page } = this.state;
        const { children, renderChildren, label } = this.props;
        if (!children) {
            return null;
        }
        const childrenPath = this.getChildrenPath();
        const errorText = this.renderError();
        const chunk = this.getChildrenChunk();
        const pagination = this.renderPagination();

        return (<div className="col-12 mt-3">
            <div className="cms-group">
                <div className="cms-group__header">
                    <span className="cms-group__angle">
                        <h1 className="cms-group__title">{label}</h1>
                        <span className="triangle"></span>
                    </span>
                    {pagination}
                    <button className="btn-cms btn-cms--link" onClick={() => this.addFields()}>
                        <span className="icon mr-4">
                            <img src={PlusIcon} alt="" />
                        </span>
                        Dodaj następną pozycję
                    </button>
                    {(page > 0) && <button className="btn-cms btn-cms--link" onClick={() => this.removeFields()}>
                        Usuń pozycję
                    </button>}
                </div>
                <div className="cms-group__content">
                    <div className="container">
                        <div className="row">
                            {renderChildren(chunk, true, childrenPath)}
                            {errorText}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}