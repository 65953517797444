import _ from 'lodash';
import React, { Component } from 'react';
import suneditor from 'suneditor';
import "suneditor/dist/css/suneditor.min.css";
import plugins from 'suneditor/src/plugins';
import "../../index.css";
import { langPL } from "../../assets/suneditor/lang";

export default class TextareaInput extends Component {

    constructor() {
        super();
        this.state = {
            currentEditor: null
        }
    }

    onChange = (e) => {
        const { onChange, path } = this.props;

        e = "<div class='sun-editor-editable'>" + e + "</div>";
        onChange(path, e);
    }

    createEditor(newPath, newValue) {
        const { path, value } = this.props;
        const editor = suneditor.create((document.getElementById(newPath ? newPath : path)), {
            lang: langPL,
            plugins: plugins,
            value: value,
            imageWidth: "50%",
            imageFileInput: false,
            font: ['Arial', 'Comis Sans MS', 'Courier New', 'Impact', 'Georgia', 'tahoma', 'Trebuchet MS', 'Verdana', 'Roboto'],
            buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['fontColor'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align'],
                ['link', 'image', 'video'],
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview', 'print']
            ],
        });
        if (newValue) {
            newValue = this.removeAddedTags(newValue);
            editor.setContents(newValue);
        } else if (value) {
            editor.setContents(value);
        }
        editor.onChange = this.onChange;
        this.setState({ currentEditor: editor });
    }

    removeAddedTags(value) {
        if (_.startsWith(value, "<div class='sun-editor-editable'>")) {
            value = _.replace(value, "<div class='sun-editor-editable'>", "");
            if (_.endsWith(value, "</div>")) {
                value = value.substring(0, value.length - 6);
            }
            value = _.trim(value);
        }
        return value;
    }

    componentDidUpdate(prevProps) {
        const { path, value } = this.props;
        const { currentEditor } = this.state;
        if (path !== prevProps.path) {
            currentEditor.destroy();
            this.createEditor(path, value);
        }
    }

    componentDidMount() {
        this.createEditor();
    }

    renderError = () => {
        const { errors, path } = this.props;
        if (errors && errors[path]) {
            return <div><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    render() {
        const { label, placeholder, wrap, path } = this.props;
        const errorText = this.renderError();

        return (<div className="col-12 mt-3">
            <div className={wrap ? "cms-group" : ""}>
                <div className="cms-group__content">
                    <div className="cms-textarea">
                        <div className="cms-item__header">
                            <h1>{label}</h1>
                        </div>
                        <div className="cms-item__body">
                            <label className="cms-item__label">Treść</label>
                            <textarea
                                autoComplete="off"
                                className="cms-item--textarea"
                                placeholder={placeholder}
                                onChange={this.onChange}
                                // value={value || ""}
                                // name={name}
                                id={path}
                                ref="input"
                            />
                            {errorText}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}