import React from 'react';
import Tooltip from "react-simple-tooltip"

import DeleteIcon from '../../assets/icons/icon--delete.svg'
import FileIcon from '../../assets/icons/icon--filePlaceholder.svg';


export default class File extends React.Component {
    state = {
        showDropdown: false
    }

    toggleDropdownMenu = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    handleDeleteFile = (i) => {
        this.props.deleteFileFromPreview(i);
    }

    renderDeleteIcon = () => {
        const { hideDeleteButton } = this.props;
        if (hideDeleteButton) {
            return null;
        }
        return (
            <a href="#!" role="button">
                <img src={DeleteIcon} alt="" className="cms-item__icon" onClick={() => this.handleDeleteFile(this.props.i)} />
            </a>
        )
    }

    render() {
        const avatar = (this.props.file.avatar) ?
            <img src={this.props.file.avatar} alt="" className="cms-file--img" /> :
            <div className="cms-file--img d-flex justify-content-center align-items-center"><img src={FileIcon} alt="" style={{ height: 50 }} /></div>;
        return (
            <div className="col-auto">
                <div className="cms-file d-flex flex-column">
                    {avatar}
                    <Tooltip content={this.props.file.file.name}>
                        <div className="cms-file--text">
                            <span className="cms-file--name">{this.props.file.file.name}</span>
                            {this.renderDeleteIcon()}
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }
}