import produce from "immer";

import {
    IS_LOADING,
    SET_FORMS,
    CLEAR_FORMS,
    SET_SELECTED_FORM,
    CLEAR_SELECTED_FORM
} from '../actions/forms';

const initial_state = {
    loading: false,
    forms: null,
    selectedForm: null
}

const formsReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING:
                draft.loading = action.loading;
                break;
            case SET_FORMS:
                draft.forms = action.forms;
                break;
            case CLEAR_FORMS:
                draft.forms = null;
                break;
            case SET_SELECTED_FORM:
                draft.selectedForm = action.form;
                break;
            case CLEAR_SELECTED_FORM:
                draft.selectedForm = null;
                break;
            default: {
                return draft;
            }
        }
    });

}

export default formsReducer;