import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { getResourcesByForm, deleteResource, clearParams } from '../actions/resources';
import List from "../components/list/list";

class ListContainer extends React.Component {

    componentDidMount() {
        const { match, getResourcesByForm } = this.props;
        let formId = _.get(match, 'params.id', null);
        if (formId) {
            getResourcesByForm(formId);
        }
    }

    render() {
        return (
            <div>
                <List {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        resources: store.resources.resources,
        loading: store.resources.loading,
        selectedForm: store.forms.selectedForm,
        forms: store.forms.forms
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getResourcesByForm: (formId) => {
            dispatch(getResourcesByForm(formId));
        },
        deleteResource: (resource) => {
            dispatch(deleteResource(resource));
        },
        clearParams: () => {
            dispatch(clearParams());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);