import React, { Component } from 'react';

import GroupInputFieldHeader from '../inputs/groupInputFieldHeader';
import BasicInput from '../inputs/basicInput';

export default class GroupInputField extends Component {

    state = {
        nameError: null,
        labelError: null,
        fieldsError: null
    }

    renderError = () => {
        const error = this.state.fieldsError;

        if (error) return <div><span className="text-danger d-flex text-align-center">{error}</span></div>

        return null;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors !== this.props.errors) {
            this.setState({ nameError: null, labelError: null, fieldsError: null })

            nextProps.errors.forEach(error => {
                switch (error.field) {
                    case 'label':
                        this.setState({ labelError: error.error })
                        break;
                    case 'name':
                        this.setState({ nameError: error.error })
                        break;
                    case 'fields':
                        this.setState({ fieldsError: error.error })
                        break;
                    default: return;
                }
            })
        }
    }

    render() {
        return (
            <div className="cms-group">
                <div className="cms-group__header" style={this.props.id ? { opacity: '0.4' } : null}>
                    <GroupInputFieldHeader
                        onChange={(e, name) => this.props.onChange(e, name)}
                        name={this.props.name}
                        id={this.props.id}
                        error={this.state.nameError} />
                </div>
                <div className="cms-group__content">
                    <BasicInput
                        type="text"
                        label="Label"
                        placeholder="Wpisz treść labela"
                        defaultValue={this.props.label}
                        error={this.state.labelError}
                        onChange={(e) => this.props.onChange(e, 'label')}
                    />
                    <br />
                    {this.renderError()}
                    <br />
                    {this.props.components}
                    {this.props.AddInputs}
                </div>
            </div>
        )
    }

}