import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';

import '../css/master.css';
import '../css/style.css';


import AuthContainer from './authContainer';
import SidePanelContainer from './sidePanelContainer';
import ItemsContainer from './itemsContainer';

//actions
import { isAuth } from '../actions/auth';

class AppContainer extends React.Component {
    componentDidMount = () => {
        this.props.isAuth();
    }

    renderContent = () => {
        if (this.props.auth.isAuth) {
            return (
                <div>
                    <SidePanelContainer />
                    <ItemsContainer />
                </div>
            )
        } else {
            return (
                <AuthContainer />
            )
        }
    }
    render() {
        return (
            <div>
                <ToastContainer
                    position={toast.POSITION.BOTTOM_LEFT}
                    autoClose={3000}
                    draggable={false}
                    hideProgressBar={true}
                    bodyClassName={
                        css({
                            fontSize: '2rem',
                            color: 'black',
                        })
                }
            />
                {this.renderContent()}
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isAuth: () => {
            dispatch(isAuth())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);