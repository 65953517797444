import React from 'react';
import { Link } from 'react-router-dom';
import libraryIcon from '../assets/icons/icon--library.svg';
import EditIcon from '../assets/icons/icon--edit.svg';
import _ from 'lodash';

class SidePanel extends React.Component {

    renderTitle = () => {
        const name = this.props.auth.name;
        return (
            <li>
                <h1 className="cms-sidebar__title">
                    {name}
                </h1>
            </li>
        )
    }

    openLibrary = () => {
        this.props.toggleLibrary();
    }

    renderLibraryButton = () => {
        return (
            <li onClick={this.openLibrary}>
                <a className="cms-sidebar__option" href="#!">
                    <span className="icon mr-4">
                        <img src={libraryIcon} alt="Library Icon" />
                    </span>
                    Biblioteka
                </a>
            </li>
        )
    }

    renderCustomButton = () => {
        return null;
    }

    newButtonClickHandler = () => {
        const { clearItems } = this.props;
        clearItems();
    }

    renderCreateNewButton = () => {
        const { auth } = this.props;
        if (auth && auth.isAdmin) {
            return (
                <li className="cms-sidebar__option" onClick={this.newButtonClickHandler}>
                    <Link to="/resources/resource" className="btn-cms btn-cms--outlined">
                        Utwórz zasób
                    </Link>
                </li>
            )
        }
        return null;
    }

    onLogoutButtonClick = () => {
        this.props.logout();
    }

    renderLogoutButton = () => {
        return (
            <li className="cms-sidebar__option">
                <button
                    className="btn-cms btn-cms--outlined"
                    onClick={this.onLogoutButtonClick}
                >
                    Wyloguj się
                </button>
            </li>
        )
    }

    setSelectedForm = (form) => {
        const { setSelectedForm, getResourcesByForm } = this.props;
        setSelectedForm(form);
        getResourcesByForm(form._id);
    }

    loadSingleForm = (form) => {
        const { clearResources, setSelectedForm, loadSelectedForm, getResource, clearAll } = this.props;
        let resourceId = form.resourceId;
        setSelectedForm(form);
        clearResources();
        clearAll();
        if (resourceId) {
            getResource(resourceId, 'PL');
        } else {
            loadSelectedForm();
        }
    }

    getSelectedForm = (id) => {
        const { getForm } = this.props;
        getForm(id);
    }

    renderFormsList = () => {
        const { forms, auth: { isAdmin } } = this.props;
        if (!forms) {
            return null;
        } else {
            return _.sortBy(forms, (f) => f.name).map((form, index) => {
                let isSingle = form.hasOwnProperty("resourceId") && !form.multiple;
                let formEditButton = isAdmin ? (<Link to={`/resources/resource/${form._id}`} className="cms-sidebar__page" onClick={() => this.getSelectedForm(form._id)}>
                    <img src={EditIcon} alt="Edit Icon" />
                </Link>) : null;
                if (isSingle) {
                    let url = `/resources/data${form.resourceId === null ? "" : ("/" + form.resourceId + "/PL")}`
                    return (
                        <li key={index} style={{ display: 'flex' }}>
                            <Link to={url} className="cms-sidebar__page col-8" onClick={() => this.loadSingleForm(form)}>
                                {form.name}
                            </Link>
                            {formEditButton}
                        </li>
                    );
                } else {
                    return (
                        <li key={index} style={{ display: 'flex' }}>
                            <Link to={`/resources/list/${form._id}`} className="cms-sidebar__page col-8" onClick={() => this.setSelectedForm(form)}>
                                {form.name}
                            </Link>
                            {formEditButton}
                        </li>
                    );
                }
            });
        }
    }

    render() {
        const forms = this.renderFormsList();
        return (
            <section className="cms-sidebar bg-primary">
                <ul>
                    {this.renderTitle()}
                    {this.renderLibraryButton()}
                    {this.renderCustomButton()}
                    {this.renderCreateNewButton()}
                    {forms}
                    {this.renderLogoutButton()}
                </ul>
            </section>
        )
    }
}

export default SidePanel;