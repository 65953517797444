import React, { Component } from 'react';

import TextareaIcon from '../../assets/icons/icon--textarea.svg'
import FileIcon from '../../assets/icons/icon--picture.svg'
import TextIcon from '../../assets/icons/icon--input.svg'
import DateIcon from '../../assets/icons/icon--date.svg'
import SelectIcon from '../../assets/icons/icon--list.svg'
import CheckboxIcon from '../../assets/icons/icon--checkbox.svg'
import GroupIcon from '../../assets/icons/icon--group.svg'
import EditIcon from '../../assets/icons/icon--edit.svg'
import MenuIcon from '../../assets/icons/icon--menu.svg'

import SecondaryButton from '../buttons/secondaryButton';
import SwitchInput from './switchInput/switchInput';

export default class itemTitleInput extends Component {

    state = {
        showMenu: false
    }

    renderError = () => {
        const { error } = this.props;

        if (error) return <div><span className="text-danger">{error}</span></div>

        return null;
    }

    setIcon = () => {
        switch (this.props.icon) {
            case 'textarea': {
                return TextareaIcon;
            }
            case 'file': {
                return FileIcon;
            }
            case 'text': {
                return TextIcon;
            }
            case 'select': {
                return SelectIcon;
            }
            case 'radio': {
                return CheckboxIcon;
            }
            case 'group': {
                return GroupIcon;
            }
            case 'date': {
                return DateIcon
            }
            default: {
                console.error('Nie mamy takiej ikonki')
                break;
            }
        }
    }

    showMenuHandler = () => {
        this.setState(prevState => {
            return { showMenu: !prevState.showMenu }
        });
    }

    handleOpenLibrary = () => {
        this.props.toggleLibrary(true);
    }

    onChange = (e, name) => {
        this.props.onChange(e, name)
    }

    renderItemHeaderButton = () => {
        const { icon } = this.props;
        switch (icon) {
            case 'file':
            case 'list':
            case 'select':
                return (
                    <SwitchInput
                        name="Wielokrotny wybór"
                        defaultValue={this.props.multiple}
                        switchFieldName={this.props.switchFieldName}
                        onChange={() => this.onChange(null, 'multiple')}
                    />
                )
            default:
                return null
        }
    }

    render() {
        const icon = this.setIcon();

        return (
            <div>
                <div className="cms-item__header">
                    <div className="cms-item__header--left">
                        <img src={icon} alt="" className="cms-item__icon mr-4" />
                        <input
                            style={this.props.id ? { opacity: '0.5' } : null}
                            type="text"
                            placeholder={this.props.placeholder}
                            className="cms-item__title"
                            defaultValue={this.props.name}
                            onChange={(e) => this.props.onChange(e, 'name')}
                            disabled={this.props.id ? true : false} />
                        <img src={EditIcon} alt="" className="cms-item__icon ml-4" />

                    </div>
                    <SwitchInput
                        name="Pole wymagane"
                        defaultValue={this.props.required}
                        isRequiredFieldName={this.props.isRequiredFieldName}
                        onChange={() => this.onChange(null, 'required')}
                    />
                    {!this.props.id ? <div className="cms-item__header--right">
                        {this.renderItemHeaderButton()}
                        <div className={this.state.showMenu ? "cms-item__dropdown dropdown dropleft show" : "cms-item__dropdown dropdown dropleft"} onClick={this.showMenuHandler}>
                            <a href="#!" role="button" id="cms-item__dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.showMenu ? "true" : "false"}>
                                <img src={MenuIcon} alt="" className="cms-item__icon" />
                            </a>
                            <div className={this.state.showMenu ? "dropdown-menu show" : "dropdown-menu dropleft"} aria-labelledby="cms-item__dropdown">
                                <a className="dropdown-item" href="#!" onClick={() => this.onChange(null, 'delete')}>Usuń</a>
                            </div>
                        </div>
                    </div> : null}
                </div>
                {this.renderError()}
            </div>
        )
    }
}