import React, { Component } from 'react';
import AddInput from './inputs/addInput';

export default class AddInputs extends Component {

    onChange = (newItems) => {
        this.props.onItemsClicked(newItems);
    }

    render() {
        const { ancestors } = this.props;

        return (
            <section className="cms-add cms-add-widget" style={{ paddingBottom: '50px' }}>
                <div className="container">
                    <div className="row cms-add__items">
                        <AddInput
                            icon="textarea"
                            type="textarea"
                            name="Obszar tekstowy"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="file"
                            type="file"
                            name="Plik"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="date"
                            type="date"
                            name="Data"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="text"
                            type="text"
                            name="Pole tekstowe"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="select"
                            type="select"
                            name="Lista wyboru"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="radio"
                            type="radio"
                            name="Pola wyboru"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                        <AddInput
                            icon="group"
                            type="group"
                            name="Grupa"
                            items={this.props.items}
                            onItemsChange={this.onChange}
                            fieldId={this.props.fieldId}
                            ancestors={ancestors}
                        />
                    </div>
                </div>
            </section>
        )
    }

}
