import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import AppContainer from './containers/appContainer';

import { Provider } from 'react-redux';

import configureStore from './config/configureStore'

export const store = configureStore();

const jsx = (
    <BrowserRouter>
        <Provider store={store}>
            <AppContainer />
        </Provider>
    </BrowserRouter>
)

ReactDOM.render(jsx, document.getElementById('root'));