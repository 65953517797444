import React, { Component } from 'react';

export default class TextInput extends Component {

    onChange = (e) => {
        const { onChange, path } = this.props;
        let value = e.target.value;
        onChange(path, value);
    }

    renderError = () => {
        const { errors, path } = this.props;
        if (errors && errors[path]) {
            return <div><span className="text-danger">{errors[path]}</span></div>
        }
        return null;
    }

    render() {
        const { name, value, label, placeholder, wrap } = this.props;
        const errorText = this.renderError();

        return (<div className="col-12 mt-3">
            <div className={wrap ? "cms-group" : ""}>
                <div className="cms-group__content">
                    <div className="cms-input">
                        <div className="cms-item__header">
                            <h1>{label}</h1>
                        </div>
                        <div className="cms-item__body">
                            <label className="cms-item__label">Treść</label>
                            <input
                                autoComplete="off"
                                className="cms-item__input cms-input__label"
                                type="text"
                                name={name}
                                placeholder={placeholder}
                                onChange={this.onChange}
                                value={value || ""}
                            />
                            {errorText}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}