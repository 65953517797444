import React, { Component } from 'react';
import _ from 'lodash';

import TextareaIcon from '../../assets/icons/icon--textarea.svg'
import FileIcon from '../../assets/icons/icon--picture.svg'
import TextIcon from '../../assets/icons/icon--input.svg'
import SelectIcon from '../../assets/icons/icon--list.svg'
import CheckboxIcon from '../../assets/icons/icon--checkbox.svg'
import GroupIcon from '../../assets/icons/icon--group.svg'
import DateIcon from '../../assets/icons/icon--date.svg'

export default class AddInput extends Component {

    setIcon = () => {
        switch (this.props.icon) {
            case 'textarea': {
                return TextareaIcon;
            }
            case 'file': {
                return FileIcon;
            }
            case 'text': {
                return TextIcon;
            }
            case 'select': {
                return SelectIcon;
            }
            case 'radio': {
                return CheckboxIcon;
            }
            case 'group': {
                return GroupIcon;
            }
            case 'date': {
                return DateIcon;
            }
            default: {
                console.error('Nie mamy takiej ikonki')
                break;
            }
        }
    }

    onClickedHandler = (type) => {
        const fieldId = this.props.fieldId;
        let ancestors = this.props.ancestors;
        let items = this.props.items;
        let path = "";

        let inputFields = { type, name: '', label: '', fieldId }
        if (type === 'text' || type === 'textarea' || type === 'date') inputFields.placeholder = '';
        if (type === 'group') inputFields.fields = [];
        if (type === 'select' || type === 'radio' || type === 'file') inputFields.multiple = false;
        if (type === 'select' || type === 'radio') inputFields.options = []

        if (ancestors === undefined) {
            ancestors = [this.props.fieldId];

            // inputFields.path = path;
            inputFields.ancestors = ancestors;
            items.push(inputFields);
        } else {
            ancestors.map((position, index) => {
                path += `[${position}].fields`;
            });

            inputFields.path = path;
            inputFields.ancestors = ancestors;
            const currentValue = _.get(items, path);
            currentValue.push(inputFields);
            _.set(items, path, currentValue);
        }

        this.props.onItemsChange(items);
    }

    render() {
        const icon = this.setIcon();

        return (
            <div className="col-auto cms-add__item" onClick={() => this.onClickedHandler(this.props.type)}>
                <a className="icon cms-add__item--textarea" href="#!">
                    <img src={icon} alt="Icon" />
                </a>
                <p className="cms-add__name">{this.props.name}</p>
            </div>
        )
    }
}