import React, { Component } from 'react';
import DeleteIcon from '../../assets/icons/icon--delete.svg'

export default class FileCard extends Component {

    renderAvatar = () => {
        const { src, name } = this.props;
        if (src) {
            return <img src={src} alt={name} className="cms-file--img" />;
        } else {
            return null;
        }
    }

    handleDelete = () => {
        const { path, shortSrc, handleDelete } = this.props;
        handleDelete(path, shortSrc);
    }

    render() {
        const { name } = this.props;
        const avatar = this.renderAvatar();

        return (
            <div className="col-auto" title={name}>
                <div className="cms-file d-flex flex-column">
                    {avatar}
                    <div className="cms-file--text user-select-none ">
                        <span className="cms-file--name pt-2">{name}</span>
                        <button className="btn btn-sm btn-link" title="Usuń" onClick={() => this.handleDelete()}><img src={DeleteIcon} alt="Usuń" /></button>
                    </div>
                </div>
            </div>
        )
    }
}