import React from 'react';
import _ from 'lodash';

import plusIcon from '../../assets/icons/icon--plus.svg';

import AddLanguageModal from './addLanguageModal';
class Header extends React.Component {
    state = {
        modalVisibility: false,
        fullName: null,
        shortName: null,
        langId: null
    }

    componentDidMount = () => {
        this.props.getSettings();
    }

    renderLanguages = () => {
        const { header, selectedLanguague, form, params, loadedList } = this.props;
        const languages = header.languages;
        if (languages) {
            return languages.map(lang => {
                let isSelected = ((selectedLanguague === _.get(lang, 'value.shortName', null)) || params.languague === _.get(lang, 'value.shortName', null));
                return (
                    <a
                        className={`cms-lang ${(isSelected && (!_.isEmpty(form) || loadedList)) ? "active" : ""}`}
                        key={lang._id}
                        onClick={() => this.handleLanguageClick(lang)}
                    >
                        {lang.value.shortName}
                    </a>
                )
            })
        }
        return;
    }

    handleLanguageClick = (lang) => {
        const { auth, form, setActiveLangForm, loadedList, setLanguagueFilter } = this.props;
        if (!_.isEmpty(form)) {
            setActiveLangForm(lang);
        } else if (loadedList) {
            setLanguagueFilter(lang);
        } else if (auth && auth.isAdmin) {
            this.setState({
                fullName: lang.value.name,
                shortName: lang.value.shortName,
                langId: lang._id
            }, () => {
                this.toggleAddLanguageModalVisibility()
            })
        }
    }

    setValues = () => {
        const { langId } = this.state;
        const { languages } = this.props.header;
        if (langId) {
            const i = _.findIndex(languages, { "_id": langId });
            this.setState({
                fullName: languages[i].value.name,
                shortName: languages[i].value.shortName,
            })
        } else {
            this.setState({
                fullName: null,
                shortName: null,
                langId: null
            })
        }
    }

    toggleAddLanguageModalVisibility = () => {
        //Clear id after modal is closed
        if (this.state.modalVisibility && this.state.langId) {
            this.setState({
                langId: null
            })
        } else {
            this.setValues();
        }

        this.setState({
            modalVisibility: !this.state.modalVisibility
        })

        this.props.clearErrors();
    }

    handleInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    handleSubmitAddLanguage = () => {
        const form = {
            key: "language",
            value: {
                name: this.state.fullName,
                shortName: this.state.shortName
            }
        }

        if (this.state.langId) {
            this.props.editLanguagueInSettings(form, this.state.langId, this.toggleAddLanguageModalVisibility);
        } else {
            this.props.addLanguagueToSettings(form, this.toggleAddLanguageModalVisibility);
        }
    }

    handleDeleteLanguage = () => {
        this.props.deleteLanguageFromSettings(this.state.langId);
        this.toggleAddLanguageModalVisibility();
    }

    handleAddLanguageButtonPress = () => {
        this.toggleAddLanguageModalVisibility();
    }

    renderAddLanguageButton = () => {
        const { auth } = this.props;
        if (auth && auth.isAdmin) {
            return (
                <button
                    className="btn-cms btn-cms--link"
                    onClick={this.handleAddLanguageButtonPress}
                >
                    <span className="icon mr-4">
                        <img src={plusIcon} alt="Plus Icon" />
                    </span>
                    Dodaj wersję językową
                </button>
            )
        }
        return null;
    }

    render() {
        const { form, loadedList } = this.props;

        let isHidden = _.isEmpty(form) && !loadedList;

        const modal = (this.state.modalVisibility) ?
            <AddLanguageModal
                handleSubmitAddLanguage={this.handleSubmitAddLanguage}
                handleDeleteLanguage={this.handleDeleteLanguage}
                toggleAddLanguageModalVisibility={this.toggleAddLanguageModalVisibility}
                handleInputChange={this.handleInputChange}
                languages={this.props.header.languages}
                langId={this.state.langId}
                shortName={this.state.shortName}
                fullName={this.state.fullName}
                error={this.props.error}
            />
            : null;
        return (
            <section className={`cms-header ${isHidden ? "d-none" : ""}`}>
                {modal}
                <div className="row no-gutters">
                    <div className="col-auto">
                        {this.renderLanguages()}
                        {this.renderAddLanguageButton()}
                    </div>
                </div>
            </section>
        )
    }
}

export default Header;