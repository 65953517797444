import _ from 'lodash';
import { toast } from 'react-toastify';
import Api from '../services/api';
import { getForms } from "./forms";
import moment from "moment";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

const namespace = 'RESOURCE';
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_FORM = `SET_FORM_${namespace}`;
export const CLEAR_FORM = `CLEAR_FORM_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;
export const SELECT_LANGUAGUE = `SELECT_LANGUAGUE_${namespace}`;
export const CHANGE_RESOURCE_NAME = `CHANGE_RESOURCE_NAME_${namespace}`;
export const SET_LANG_FORM = `SET_LANG_FORM_${namespace}`;
export const CLEAR_ALL = `CLEAR_ALL_${namespace}`;
export const SET_ACTIVE_LANG_FROM = `SET_ACTIVE_LANG_FROM_${namespace}`
export const SET_BASE_FORM = `SET_BASE_FORM_${namespace}`;
export const SET_RESOURCE_ID = `SET_RESOURCE_ID_${namespace}`;
export const CHANGE_IS_PRIVATE = `CHANGE_IS_PRIVATE_${namespace}`;

export const isLoading = (loading) => dispatch => {
    dispatch({
        type: IS_LOADING,
        loading
    });
}

export const setForm = (data) => (dispatch) => {
    dispatch({
        type: SET_FORM,
        form: data
    });
}

//Pobranie danych do zbudowania formularza
export const getForm = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/resource/resource/${id}`);
    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setForm(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

//Funkcja czyści formularz zasobu
export const clearForm = () => (dispatch) => {
    dispatch({
        type: CLEAR_FORM
    });
}

export const clearAll = () => (dispatch) => {
    dispatch({
        type: CLEAR_ALL
    });
}

export const setResourceId = (resourceId) => (dispatch) => {
    dispatch({
        type: SET_RESOURCE_ID,
        resourceId: resourceId
    });
}

export const setActiveLangForm = (lang) => (dispatch, getState) => {
    const { resource: { forms, baseForm } } = getState();
    let shortLangName = _.get(lang, 'value.shortName', "PL");
    if (forms[shortLangName]) {
        dispatch(changeName(forms[shortLangName].name));
        dispatch(setIsPrivate(forms[shortLangName].private));
        dispatch(setForm(JSON.parse(JSON.stringify(forms[shortLangName]))));
    } else {
        dispatch(setForm(JSON.parse(JSON.stringify(baseForm))));
    }
    dispatch(selectLanguague(lang));

}

//Funkcja ustawia template pustego formularza w store
export const setBaseForm = (form) => (dispatch) => {
    dispatch({
        type: SET_BASE_FORM,
        form: form
    });
}

export const setLangForm = (lang, form) => (dispatch) => {
    dispatch({
        type: SET_LANG_FORM,
        lang: lang,
        form: form
    });
}

export const getResource = (resourceId, lang) => async (dispatch, getState) => {
    const { library: { files } } = getState();
    dispatch(isLoading(true));
    dispatch(clearErrors());

    let res = await Api.get(`/resource/data?resource=${resourceId}`);

    if (res && res.success) {
        let resources = res.documents;
        if (_.isEmpty(resources)) {
            toast("Nie znaleziono zasobu");
        } else {
            dispatch(setResourceId(resourceId));
            //Pobranie formularza dla zasobów dla wszystkich języków - wszystkie mają ten sam
            let formId = _.get(resources, "[0].form", null);
            if (formId) {
                let form = null;
                let res2 = await Api.get(`/resource/resource/${formId}`);
                if (res2 && res2.success) {
                    form = _.get(res2, 'document', null);
                } else {
                    toast('Błąd pobierania formularza');
                }
                if (form) {
                    dispatch(setBaseForm(JSON.parse(JSON.stringify(form))));
                    //Flaga oznaczająca czy ustawiono formularz w języku z url
                    let wasSet = false;
                    //Do zasobu w każdym języku tworzymy formularz z podstawionymi danymi
                    resources = resources.map((resource) => {
                        let formLanguague = _.get(resource, 'languague');
                        let formData = JSON.parse(JSON.stringify(form));
                        formData.name = resource.name;
                        formData.private = resource.private;
                        formData.resourceId = resource._id;
                        formData.fields = setFormValues(formData.fields, resource.fields, false, null, dispatch);
                        dispatch(setLangForm(formLanguague, formData));
                        //Jeśli język zasobu to ten z url zaczytujemy formularz do edycji
                        if (formLanguague === lang) {
                            dispatch(selectLanguague(formLanguague));
                            dispatch(setIsPrivate(formData.private));
                            dispatch(changeName(formData.name));
                            dispatch(setForm(formData));
                            wasSet = true;
                        }
                    });
                    //Jeśli nie ustawiono formularza w języku z url podstawiamy pusty
                    if (!wasSet) {
                        dispatch(selectLanguague(lang));
                        dispatch(setIsPrivate(form.private));
                        dispatch(changeName(form.name));
                        dispatch(setForm(_.cloneDeep(form)));
                    }
                    dispatch(isLoading(false));
                }
            } else {
                toast("Wystąpił błąd");
            }
        }
    }
}

//formFields - Pola formularza na których ustawiamy wartości, resourceFields - pola z zapisanymi wartościami, nested - czy ustawiamy wartości grupy, path - dotychczasowa ścieżka
export const setFormValues = (formFields, resourceFields, nested = false, path = null, dispatch) => {
    if (nested) {
        if (_.isEmpty(resourceFields)) {
            return JSON.parse(JSON.stringify(formFields));
        }
        let parsed = [];
        // parsed = JSON.parse(JSON.stringify(formFields));
        //Grupujemy pola w grupie tak jak są wyświetlane na stronie
        let grouped = _.groupBy(resourceFields, (f) => f.group || 1);
        //Do każdej grupy dodajemy brakujące pola jeśli takie istnieją i ustawiamy wartości
        _.each(grouped, (group, key) => {
            //Index pola w tablicy fields parenta
            //W formularzu jeśli jest kilka grup to pola ze wszystkich grup są w tablicy na tym samym poziomie tylko mają atrybut group inny
            //Jeśli je pogrupujemy po tym atrybucie to w każdej grupie indexy będą od 0 a chcemy mieć oryginalny index pola taki jak przed grupowaniem
            let groupIndex = 0;
            //Dla każdej grupy robimy map po polach formularza, szukamy do nich wartości i zwracamy pola formularza
            let toAdd = formFields.map((template) => {
                let field = JSON.parse(JSON.stringify(template));
                if (parseInt(key) > 1) {
                    field.added = true;
                }
                //
                let resourceField = _.find(group, (f) => f.fieldId === field._id);
                if (resourceField) {
                    if (field.type === "group") {
                        field.fields = setFormValues(field.fields, resourceField.value, true, `fields[${groupIndex}]`, dispatch);
                    } else if(field.type === "date") {
                        field.value = moment(resourceField.value).format("YYYY-MM-DD");
                    } else {
                        field.value = resourceField.value;
                    }
                } else {
                    field.value = undefined;
                }
                field.group = key;
                groupIndex += 1;
                return field;
            });
            parsed = _.concat(parsed, toAdd);
        });
        return parsed;

    } else {
        //Dla pól nie będacych w grupie po prostu ustawiamy wartości
        return formFields.map((field, index) => {
            let resourceField = _.find(resourceFields, (f) => f.fieldId === field._id);
            if (resourceField) {
                switch (field.type) {
                    case "date":
                        field.value = moment(resourceField.value).format("YYYY-MM-DD");
                        break;
                    case "text":
                    case "textarea":
                    case "select":
                    case "radio":
                    case "file":
                    default:
                        field.value = resourceField.value;
                        break;
                    case "group":
                        field.fields = setFormValues(field.fields, resourceField.value, true, `fields[${index}]`, dispatch);
                        break;
                }
            } else {
                field.value = undefined;
            }
            return field;
        });
    }
}


//Funkcja zapisuje wypełniony formularz
export const saveResource = (resourceId = null, push) => async (dispatch, getState) => {
    dispatch(isLoading(true));
    dispatch(clearErrors());
    const { resource: { form, name, languague, resourceId }, library: { selectedFiles } } = getState();
    const isPrivate = getState().resource.private;
    let data = {
        private: isPrivate,
        form: form._id,
        languague: languague || "PL",
        fields: parseFormData(form.fields, selectedFiles)
    };
    if (name) {
        data.name = name;
    }
    if (resourceId) {
        data.resource = resourceId;
    }
    let res = null;
    if (form.resourceId) {
        res = await Api.put(`/resource/data/${form.resourceId}`, data);
    } else {
        res = await Api.post("/resource/data", data);
    }

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
        dispatch(getForms());
        if (!resourceId && push) {
            let resourceId = _.get(res.document, 'resource');
            let lang = _.get(res.document, 'languague');
            if (resourceId) {
                dispatch(getResource(resourceId, languague || "PL"));
            }
            if (resourceId && lang) {
                push(`/resources/data/${resourceId}/${languague || "PL"}`);
            } else {
                push("/");
            }

        }
    } else {
        //Ustawienie błędów walidacji formularza
        if (res.err && _.isObject(res.err)) {
            dispatch(setValidationErrors(res.err, data));
        }
        toast('Wystąpił błąd');
    }
}

//Funkcja zapisuje w reducerze zmiany w wartościach pól formularza
export const onResourceChange = (path, value) => (dispatch, getState) => {
    const { resource: { form } } = getState();

    let field = _.get(form, path, null);
    let updatedForm = Object.assign({}, form);

    if (field) {
        let newValue = null;
        switch (field.type) {
            case "text":
            case "select":
            case "date":
            case "file": {
                newValue = value;
                break;
            }
            case "radio": {
                newValue = _.xor(_.isArray(field.value) ? field.value : [], _.castArray(value));
                break;
            }
            case "textarea": {
                let reg = new RegExp("(<(?!\/)[^>]+>)+(<\/[^>]+>)+", "g");
                let checkEmpty = _.trim(_.replace(value, reg, ""));
                if (_.isEmpty(checkEmpty)) {
                    newValue = undefined;
                } else {
                    newValue = value;
                }
                break;
            }
            default: {
                return false;
            }
        }
        _.set(updatedForm, path + ".value", newValue);
        dispatch(setForm(updatedForm));
    }
}

export const deleteFileFromForm = (path, value) => (dispatch, getState) => {
    const { resource: { form } } = getState();

    let field = _.get(form, path, null);
    let updatedForm = Object.assign({}, form);

    if (field) {
        let newValue = field.multiple ? _.without(field.value, value) : undefined;
        if (field.multiple && _.isEmpty(newValue)) {
            newValue = undefined;
        }
        _.set(updatedForm, path + ".value", newValue);
        dispatch(setForm(updatedForm));
    }
}

//Funkcja przygotowuje dane formularza do zapisania w formacie name - nazwa pola, value - wartość pola, parent - parent dodanego pola, copyType - oryginalna nazwa pola 
//fields - pola formularza zasobu, data - dane wypełnione w formularzu, selectedFiles - wybrane pliki
function parseFormData(fields, selectedFiles, nested = false) {
    //Jeśli pole jest grupą to moze mieć skopiowane wartości
    return fields.map((field) => {
        let obj = _.pick(field, ["name", "added", "value", "group"]);
        if (!obj.group && nested) {
            obj.group = 1;
        }

        //Dla pola typu plik ustawiamy jako wartość src wybranych plików
        if (field.type === "file") {
            if (_.isArray(selectedFiles[field.name])) {
                obj.value = selectedFiles[field.name].map((file) => file.src);
                //jeśli pole plik umożliwia wybranie tylko jednego pliku zapisujemy tylko jeden src nie tablice
                if (!field.multiple) {
                    obj.value = _.head(obj.value);
                }
            }
            //Wartości pola grupa ustawiamy rekurencyjnie
        } else if (field.type === "group") {
            obj.value = parseFormData(field.fields, selectedFiles, true);
        }
        return obj;
    });
}


//Funkcja zamienia ścieżki błędów walidacji na nazwy inputów i ustawia błędy w reducerze
export const setValidationErrors = (errors, data) => (dispatch, getState) => {
    let parsed = {};
    _.each(errors, (value, key) => {
        //Scieżka błędów zwrócona z api niestety wygląda tak dla tablic: x.0.y więc trzeba zamienić na x[0].y
        if (_.includes(key, "fields")) {
            let props = _.split(key, ".");
            let path = "";
            props.map((prop, index) => {
                if (index !== props.length - 1) {
                    path += !isNaN(prop) ? `[${prop}]` : `${index > 0 ? "." : ""}${prop}`;
                }
                return null;
            });
            parsed[path] = value;
        } else {
            parsed[key] = value;
        }
    });
    dispatch(clearErrors());
    dispatch({
        type: SET_ERRORS,
        errors: parsed
    });
}

//Funkcja czyści błędy walidacji formularza
export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS,
    });
}

//Ładowanie danych wybranego formularza do tworzenia zasobu
export const loadSelectedForm = () => async (dispatch, getState) => {
    const { forms: { selectedForm } } = getState();
    if (selectedForm) {
        dispatch(selectLanguague("PL"));
        dispatch(setForm(Object.assign({}, selectedForm)));
    }
}

//Funkcja zmienia język aktualnie edytowanego zasobu
export const selectLanguague = (languague) => (dispatch) => {
    let lang = null;
    if (_.isObject(languague)) {
        lang = _.get(languague, 'value.shortName', "PL");
    } else {
        lang = languague;
    }
    if (lang) {
        dispatch({
            type: SELECT_LANGUAGUE,
            languague: lang
        });
    }
}

//Funkcja zmienia nazwę aktualnie edytowanego zasobu
export const changeName = (name) => (dispatch) => {
    dispatch({
        type: CHANGE_RESOURCE_NAME,
        name: name
    });
}

export const setIsPrivate = (isPrivate) => (dispatch) => {
    dispatch({
        type: CHANGE_IS_PRIVATE,
        private: isPrivate
    });
}

//Funkcja parsuje pola do dodania
export const parseFieldsToAdd = (fields, addAmount) => {
    let sliced = _.slice(fields, 0, addAmount);
    let newFields = [];

    _.each(sliced, (field) => {
        let newField = _.pick(field, ["name", "label", "placeholder", "type", "options", "fieldsLength"]);

        if (field.fields) {
            newField.fields = parseFieldsToAdd(field.fields, field.fieldsLength);
        }
        newField.value = undefined;
        newField.group = _.size(fields) / addAmount + 1;
        newField.added = true;
        newFields.push(newField);
    });
    return newFields;
}

//Funkcja dodaje pola w grupie
export const addFields = (path) => (dispatch, getState) => {
    const { resource: { form } } = getState();
    let updatedForm = Object.assign({}, form);
    let field = _.get(form, path, null);
    if (field) {
        let toAdd = parseFieldsToAdd(field.fields, field.fieldsLength);
        _.set(updatedForm, path + ".fields", _.concat(field.fields, toAdd));
        dispatch(setForm(updatedForm));
    }
}

export const removeFields = (path, index) => (dispatch, getState) => {
    const { resource: { form } } = getState();
    let updatedForm = Object.assign({}, form);
    let field = _.get(updatedForm, path, null);
    if (field) {
        let newFields = _.compact(field.fields.map((f) => {
            if (f.group == index + 1) {
                return null;
            }
            if (f.group > index + 1) {
                f.group -= 1;
            }
            return f;
        }));
        _.set(updatedForm, path + '.fields', newFields);
        dispatch(setForm(updatedForm));
    }
}


