import { toast } from 'react-toastify';

import Api from '../services/api';

const namespace = 'HEADER';
export const SET_LANGUAGES = `SET_LANGUAGES_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;

export const deleteLanguageFromSettings = (id) => dispatch => {
    Api.delete(`/application/settings/${id}`).then(res => {
        if (res.success) {
            toast('Usunięto język')
            dispatch(getSettings());
        } else {
            toast('Usuwanie języka nie powiodło się')
        }
    }).catch(err => {
        console.error(err)
    })
}

const setErrors = (error) => dispatch => {
    dispatch({
        type: SET_ERRORS,
        error
    })
}

export const clearErrors = () => dispatch =>{
    dispatch({
        type: CLEAR_ERRORS
    })
}

export const editLanguagueInSettings = (form, id, toggleModal) => dispatch => {
    Api.put(`/application/settings/${id}`, form).then(res => {
        if (res.success) {
            toast('Zedytowano ustawienia')
            dispatch(getSettings());
            toggleModal();
            dispatch(clearErrors());
        } else {
            if (res.err) {
                dispatch(setErrors(res.err.value));
            }
            toast('Edycja ustawień nie powiodła się')
        }
    }).catch(err => {
        console.error(err)
    })
}

export const addLanguagueToSettings = (form, toggleModal) => dispatch => {
    Api.post('/application/settings', form).then(res => {
        if (res.success) {
            toast('Dodano język')
            dispatch(getSettings());
            dispatch(clearErrors());
            toggleModal();
        } else {
            if (res.err) {
                dispatch(setErrors(res.err.value));
            }
            toast('Dodawanie języka nie powiodło się')
        }
    }).catch(err => {
        console.error(err)
    })
}

export const getSettings = () => dispatch => {
    Api.get('/application/settings').then(res => {
        if (res.success) {
            const languages = res.documents;
            dispatch({
                type: SET_LANGUAGES,
                languages
            });
        } else {
            toast('Pobieranie ustawień nie powiodło się')
        }
    }).catch(err => {
        console.error(err)
    })
}